import React from "react";
import { UpdateControl } from "../../common/communication.base";
import { NclActionSeparator } from "../../common/components.ncl";
import { useServerState } from "../hooks";
import { StyleHelper, WithContextPlacementProps } from "../k2hoc";
import ActionSeparator from "./ActionSeparator";

const K2ActionSeparator = (props: WithContextPlacementProps & { color?: string }) => {
  const [control, data, element] = useServerState<NclActionSeparator, UpdateControl, HTMLDivElement>(
    props.controlUID,
    props.vrUID,
    (ctrl) => ctrl instanceof NclActionSeparator
  );

  const clr = props.color ? props.color : control.VCX.getColor(control.VCX.Data.ColorMap.ContentFrame1);

  return (
    <div ref={element} style={StyleHelper(control, { ...props.style, display: "flex", width: "auto", flex: "0 0 auto" })}>
      <ActionSeparator width={control.VCX.sizeMap(1)} color={clr} />
    </div>
  );
};

export default K2ActionSeparator;
