import React, { useRef } from "react";
import {
  ReportPart,
  TARGraphSerieType,
  TARLegendPosition,
  TARPresentationType,
  TChartState,
  TSChLegendStyle,
  TSChLegendTextStyle,
  TSChMarksStyle,
  UpdateSimpleChart,
  getMarksVisible,
} from "../../common/communication.base";
import { NclSimpleChart } from "../../common/components.ncl";
import { useServerState } from "../hooks";
import { StyleHelper, WithContextPlacementProps } from "../k2hoc";
import K2Button from "../Button/K2Button";
import css from "./SimpleChart.scss";
import { BarChart, getLegendPosition, LineChart, PieChart, chartColorSet } from "../Dashboard/K2Dashboard";
import { Chart, ChartEvent, ChartOptions, LegendItem } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import zoomPlugin from "chartjs-plugin-zoom";

Chart.register(ChartDataLabels);
Chart.register(zoomPlugin);

const K2SimpleChart = (props: WithContextPlacementProps) => {
  const [control, data, element] = useServerState<NclSimpleChart, UpdateSimpleChart, HTMLDivElement>(
    props.controlUID,
    props.vrUID,
    (ctrl) => ctrl instanceof NclSimpleChart
  );

  const options = (data: ReportPart, state: UpdateSimpleChart): ChartOptions => {
    return {
      maintainAspectRatio: false,
      plugins: {
        legend: {
          onClick: function (e, legendItem, legend) {
            if (!state.ChartLegendCheckBoxes && data.Series.length <= 1) return;
            const index = legendItem.datasetIndex;
            const ci = legend.chart;
            if (ci.isDatasetVisible(index)) {
              ci.hide(index);
              legendItem.hidden = true;
            } else {
              ci.show(index);
              legendItem.hidden = false;
            }
          },
          display: data?.LegendPosition !== TARLegendPosition.glpNone,
          position: getLegendPosition(data?.LegendPosition),
          title: {
            display: !!data?.LegendTitle,
            text: data?.LegendTitle,
          },
          labels: {
            generateLabels: (chart) => {
              const result = new Array<LegendItem>();
              switch (data.LegendStyle) {
                case TSChLegendStyle.lsAuto:
                case TSChLegendStyle.lsValues:
                  if (data.Series) {
                    let colorNdx = 0;
                    if (data.Series.length === 1) {
                      data.Series[0].DataPoints.map((dp, ndx) => {
                        result.push({
                          text: dp.FomattedValue,
                          datasetIndex: ndx,
                          fillStyle: data.DefaultPresentation == TARPresentationType.ptPieChart ? chartColorSet[colorNdx] : data.Series[0].Color,
                        });
                        colorNdx = colorNdx < chartColorSet.length - 1 ? colorNdx + 1 : 0;
                      });
                    } else if (data.Series.length > 1) {
                      data.Series.map((serie, ndx) => {
                        result.push({ text: serie.Title, datasetIndex: ndx, fillStyle: chartColorSet[colorNdx], hidden: !chart.isDatasetVisible(ndx) });
                        colorNdx = colorNdx < chartColorSet.length - 1 ? colorNdx + 1 : 0;
                      });
                    }
                    break;
                  }
                default:
                  data.Series.map((item, index) => {
                    result.push({ text: item.Title, datasetIndex: index, fillStyle: item.Color });
                  });
                  break;
              }
              return result;
            },
          },
        },
        datalabels: {
          display: getMarksVisible(data),
          anchor: "end",
          align: "end",
          offset: 10,
          rotation: data?.MarksAngle * -1,
          formatter(value, context) {
            return data?.Series[context.datasetIndex].DataPoints[context.dataIndex]?.FomattedLabel;
          },
        },
        tooltip: {
          backgroundColor: "white",
          borderColor: "#ccc",
          borderWidth: 1,
          cornerRadius: 0,
          bodyColor: "black",
          titleColor: "black",
        },
        zoom: {
          zoom: {
            wheel: {
              enabled: state?.ChartZoomAllow,
            },
            pinch: {
              enabled: state?.ChartZoomAllow,
            },
            mode: "xy",
          },
        },
      },
      interaction: {
        intersect: true,
        mode: "nearest",
      },
      scales: {
        y: {
          grid: {
            borderDash: [10, 5],
          },
          ticks: {
            autoSkip: false,
            minRotation: data?.LabelsAngleAxisY,
            maxRotation: data?.LabelsAngleAxisY,
          },
        },
        x: {
          ticks: {
            autoSkip: false,
            minRotation: data?.LabelsAngleAxisX,
            maxRotation: data?.LabelsAngleAxisX,
          },
        },
      },
      onClick: (e: unknown, data: { datasetIndex: number; index: number }[]) => {
        if (data.length > 0) {
          control.click(data[0].datasetIndex, data[0].index);
        }
      },
    };
  };

  let content: JSX.Element = undefined;

  switch (data.ChartInnerState) {
    case TChartState.csError:
      content = data.ChartErrorMessage ? <p className={css.sch_errorMessage}>{data.ChartErrorMessage}</p> : undefined;
      break;
    case TChartState.csShowingData: {
      if (control.Data && control.Data.Series.length > 0) {
        switch (control.Data.DefaultPresentation) {
          case TARPresentationType.ptBarChart:
            content = <BarChart part={control.Data} options={options(control.Data, data)} explicitChartType={control.Data.DefaultPresentation} />;
            break;
          case TARPresentationType.ptLineChart:
            content = (
              <LineChart part={control.Data} options={options(control.Data, data)} isAreaChart={false} explicitChartType={control.Data.DefaultPresentation} />
            );
            break;
          default:
            content = <PieChart part={control.Data} options={options(control.Data, data)} explicitChartType={control.Data.DefaultPresentation} />;
            break;
        }
      }
      break;
    }
  }

  return (
    <div ref={element} style={StyleHelper(control, { ...props.style, position: "relative" })}>
      {/* {control.ForceRefreshButton&&<K2Button controlUID={control.ForceRefreshButton.Ncl.ControlUID} vrUID={control.getRealizerUID()}/>} */}
      <div className={css.sch_chart}>
        {content}
        {control.RightMenuButton && data.ChartInnerState === TChartState.csShowingData && (
          <K2Button style={{ height: "24px", width: "24px" }} controlUID={control.RightMenuButton.Ncl.ControlUID} vrUID={control.getRealizerUID()} />
        )}
        {control.ForceRefreshButton && data.ChartInnerState === TChartState.csError && (
          <K2Button style={{ height: "24px", width: "24px" }} controlUID={control.ForceRefreshButton.Ncl.ControlUID} vrUID={control.getRealizerUID()} />
        )}
      </div>
    </div>
  );
};

export default K2SimpleChart;
