import React from "react";
import { DisplayMode } from "../../common/communication.base";
import { DecorateResult } from "../../common/visualContext";
import { WithVCXProps } from "../k2hoc";
import K2TruncateText from "./K2TruncateText";
import css from "./Text.scss";

interface TextProps extends WithVCXProps {
  displayMode?: DisplayMode;
  linkDecorate?: DecorateResult;
  children: string;
  className?: string;
  rawRender?: boolean;
  color?: string;
  lines?: number;
}

const K2Text = (props: TextProps) => {
  const getClassName = () => {
    let className = css.text;

    if (props.displayMode === DisplayMode.fpdmNone) {
      className += ` ${css.text_hide}`;
    }

    if (props.displayMode === DisplayMode.fpdmDecoratedBold || props.displayMode === DisplayMode.fpdmNormalBold) {
      className += ` ${css.text_bold}`;
    }

    if (props.displayMode === DisplayMode.fpdmDecoratedBold || props.displayMode === DisplayMode.fpdmDecorated) {
      className += ` ${css.text_decorated}`;
    }

    return className;
  };

  if (!props.rawRender) {
    return (
      <div className={props.className}>
        <K2TruncateText
          className={getClassName()}
          textColor={props.color}
          line={props.lines}
          style={{ color: props.linkDecorate ? props.linkDecorate.backColor : null }}
        >
          {props.children}
        </K2TruncateText>
      </div>
    );
  } else {
    return <div className={props.className}>{props.children}</div>;
  }
};

export default K2Text;
