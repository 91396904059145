import React from "react";
import { UpdateDynamicContent } from "../../common/communication.base";
import { NclDynamicContent } from "../../common/components.ncl";
import { useServerState } from "../hooks";
import { GenerateControl } from "../K2GenerateControl";
import { StyleHelper, WithContextPlacementProps } from "../k2hoc";

const K2DynamicContent = (props: WithContextPlacementProps) => {
  const [control, data, element] = useServerState<NclDynamicContent, UpdateDynamicContent, HTMLDivElement>(
    props.controlUID,
    props.vrUID,
    (ctrl) => ctrl instanceof NclDynamicContent
  );

  const ctrl = control.Children.first();
  let content: JSX.Element = null;

  if (ctrl) {
    content = GenerateControl(ctrl);
  }

  return (
    <div ref={element} key={data.DataVersion} style={StyleHelper(control, props.style)}>
      {content}
    </div>
  );
};

export default K2DynamicContent;
