import { Log } from "../../common/common";

export type Coordinates = {
  lat: number;
  lng: number;
};
export type StructuredQuery = {
  amenity?: string;
  city?: string;
  country?: string;
  state?: string;
  postalcode?: number;
  county?: string;
  street?: string;
};

export type PendingRequest = {
  url: string;
  resolve: any;
  reject: any;
};

export const FETCH_INTERVAL = 1000; //ms

class GeocodingManager {
  private static instance: GeocodingManager;
  private cache: Map<string | StructuredQuery, Coordinates>;
  private timeout: number;
  private pendingRequest: PendingRequest;

  private searchURL = "https://nominatim.openstreetmap.org/search";

  constructor() {
    this.cache = new Map<string | StructuredQuery, Coordinates>();
    this.timeout = undefined;
  }

  private static get Instance(): GeocodingManager {
    if (!GeocodingManager.instance) GeocodingManager.instance = new GeocodingManager();

    return GeocodingManager.instance;
  }

  public static searchForCoordiantes(query: string): Promise<Coordinates>;
  public static searchForCoordiantes(struct: StructuredQuery): Promise<Coordinates>;
  public static searchForCoordiantes(queryOrStruct: string | StructuredQuery) {
    return new Promise<Coordinates>(async (resolve, reject) => {
      const cachedData = GeocodingManager.Instance.cache.get(queryOrStruct);
      if (cachedData) {
        resolve(cachedData);
      }

      const searchParams = [];
      if (typeof queryOrStruct === "string") {
        searchParams.push(`q=${queryOrStruct}`);
      } else {
        for (const [key, value] of Object.entries(queryOrStruct)) {
          if (value) searchParams.push(`${key}=${value}`);
        }
      }

      GeocodingManager.PendingRequest = {
        url: `${GeocodingManager.Instance.searchURL}?` + searchParams.join("&") + "&format=jsonv2&limit=1",
        resolve: (data: any) => {
          console.log(data);
          if (data.length > 0) {
            const coord = { lat: data[0].lat, lng: data[0].lon };
            GeocodingManager.Instance.cache.set(queryOrStruct, coord);
            resolve(coord);
          } else reject("Nothing found");
        },
        reject: reject,
      };
    });
  }

  private static set PendingRequest(value: PendingRequest) {
    GeocodingManager.Instance.pendingRequest = value;
    if (!GeocodingManager.Instance.timeout) GeocodingManager.Instance.fetchRequest();
  }

  private async fetchRequest() {
    if (!GeocodingManager.Instance.pendingRequest) return;

    try {
      console.log(Date.now());
      const res = await fetch(GeocodingManager.Instance.pendingRequest.url);
      const data = await res.json();

      GeocodingManager.Instance.timeout = window.setTimeout(() => {
        GeocodingManager.Instance.timeout = undefined;
        if (GeocodingManager.Instance.pendingRequest) this.fetchRequest();
      }, FETCH_INTERVAL);

      GeocodingManager.Instance.pendingRequest?.resolve(data);
    } catch (error) {
      Log.error(error.message, error);
      GeocodingManager.Instance.pendingRequest?.reject(error);
    }
    GeocodingManager.Instance.pendingRequest = undefined;
  }
}

export default GeocodingManager;
