import React, { useEffect } from "react";

import { UpdateMap } from "../../common/communication.base";
import { NclMap } from "../../common/components.ncl";
import { useServerState } from "../hooks";
import { WithContextPlacementProps } from "../k2hoc";
import { __ } from "../../appcontext";
import K2OpenStreetMap from "./K2OpenStreetMap";
import "./Map.scss";
import K2GoogleMap from "./K2GoogleMap";

const defaultZoom = 10;

const K2Map = (props: WithContextPlacementProps) => {
  const [control, data, element] = useServerState<NclMap, UpdateMap, HTMLDivElement>(props.controlUID, props.vrUID, (ctrl) => ctrl instanceof NclMap);

  function getMapLink() {
    const base = "https://www.google.com/maps/search/";
    if (data.GPS) return base + `?api=1&query=${data.GPS}`;
    else return base + `?api=1&query=${data.Street} ${data.DescrNo}/${data.OrientNo}, ${data.City}, ${data.Country}`;
  }

  // Načtení při otevření komponenty
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="map">
      {control.Ncl.GoogleApiKey && <K2GoogleMap data={data} mapLink={getMapLink()} apiKey={control.Ncl.GoogleApiKey} />}
      {!control.Ncl.GoogleApiKey && <K2OpenStreetMap data={data} mapLink={getMapLink()} />}
    </div>
  );
};

export default K2Map;
