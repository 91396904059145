import React from "react";
import { Align, UpdateImage } from "../../common/communication.base";
import { NclImage } from "../../common/components.ncl";
import { VisualContext } from "../../common/visualContext";
import { useServerState } from "../hooks";
import { StyleHelper, WithContextPlacementProps } from "../k2hoc";
import K2Img from "./K2Img";

const K2Image = (props: WithContextPlacementProps) => {
  const [control, data, element] = useServerState<NclImage, UpdateImage, HTMLDivElement>(props.controlUID, props.vrUID, (ctrl) => ctrl instanceof NclImage);

  let img = null;

  if (data.ImageDeny === true) {
    img = getDenyImage(control.VCX, data.Stretch, control.MetaData.Bounds.Align, control.ComputedMinHeight);
  } else {
    img = (
      <K2Img
        k2Id={control.MetaData.Name}
        glyphId={data.ObjectId && data.ObjectId !== "" ? data.ObjectId : data.GlyphId}
        vcx={control.VCX}
        height={control.MetaData.Bounds.Align != Align.Client ? control.ComputedMinHeight : null}
        stretch={data.Stretch}
      />
    );
  }
  return (
    <div style={StyleHelper(control, props.style)} ref={element}>
      {img}
    </div>
  );
};

export function getDenyImage(vcx: VisualContext, stretch = false, align: Align = Align.Client, minHeight = 0): JSX.Element {
  return (
    <K2Img
      glyphId="wui*disagree"
      vcx={vcx}
      height={align != Align.Client ? minHeight : null}
      stretch={stretch}
      strokeColor={vcx.ColorMap.getColorRGB(vcx.Data.ColorMap.ContentNormalColorFrg).toHTML(0.2)}
      backgroundColor={vcx.ColorMap.getColor(vcx.Data.ColorMap.DataBrowseColorBck)}
    />
  );
}

export default K2Image;
