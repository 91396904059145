import React from "react";
import { NclMultiContent, NclPage } from "../../common/components.ncl";
import K2Tab from "../TabControl/K2Tab";
import "./MobileStaticTabs.scss";

interface MobileStaticTabsProps {
  control: NclMultiContent;
  currentPage: string;
}

function K2MobileStaticTabs(props: MobileStaticTabsProps) {
  if (!props.control) return;

  const getClassName = (page: NclPage) => {
    let className = "mst_item";

    if (props.currentPage === page.Ncl.PageUID) {
      className += " mst_current_page";
    }

    return className;
  };

  return (
    <div className="mst">
      {props.control.visiblePages().map((page) => {
        return (
          <K2Tab
            key={page.MetaData.ControlUID + "." + page.key}
            controlUID={page.MetaData.ControlUID}
            vrUID={props.control.getRealizerUID()}
            className={getClassName(page)}
          />
        );
      })}
    </div>
  );
}

export default K2MobileStaticTabs;
