import React from "react";
import Table, { ColumnType } from "antd/es/table";
import {
  UpdateInnerTreeView,
  ColumnsProportion,
  ColumnProportionEm,
  CSColumnBase,
  CSTreeColumn,
  TAlignment,
  TTreeDataHasChildNodes,
  TTreeDataMoveNodeMode,
  TTreeViewNodeCheckState,
  TTreeViewCheckingMode,
} from "../../common/communication.base";
import { K2TreeViewItem, TreeViewListener, NclInnerTreeView, TreeOperations } from "../../common/components.ncl";
import { HeaderColumnProps } from "../DataGrid/utils";
import { K2ComponentState, WithContextPlacementProps, AcquireControl, StyleHelper } from "../k2hoc";
import K2Cell from "../DataGrid/K2Cell";
import HeaderColumn from "../DataGrid/K2HeaderColumn";
import "antd/lib/table/style/index.css";
import css from "./InnerTreeView.scss";
import { writeToCSS } from "../VCX/VCXHelper";
import ResizeObserver from "resize-observer-polyfill";
import resolveContextMenu from "../../utils/resolveContextMenu";
import { Context } from "../../appcontext";

interface InnerTreeViewState extends K2ComponentState<UpdateInnerTreeView> {
  columns: ColumnType<K2TreeViewItem>[];
  expandedKeys: string[];
  checkedKeys: string[];
  columnIndex: number;
  arrow: string;
  drag: boolean;
  width: number;
  height: number;
  selectedRowKey: string;
  clientWidthDiffs?: number[];
  dragging: boolean;
  xDragPos: number;
  dataSource: K2TreeViewItem[];
  reordered: boolean;
  deleted: boolean;
}

export class K2InnerTreeView extends React.PureComponent<WithContextPlacementProps, InnerTreeViewState> implements TreeViewListener {
  static displayName = `K2InnerTreeView`;
  private control: NclInnerTreeView;
  private rect: DOMRect;
  private expandedNodes = new Array<any>();
  private draggedNode: K2TreeViewItem;
  private innerTreeViewDiv = React.createRef<HTMLDivElement>();
  private ro: ResizeObserver;
  private columns: DOMRect[];
  private draggedColumnIndex: number;
  private hoveredColumnIndex: number;
  private hackScroll: Element;

  constructor(props: WithContextPlacementProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclInnerTreeView;
    }) as NclInnerTreeView;
    let expandedKeys: string[] = [];
    if (this.control.Root) {
      expandedKeys = this.control.Root.getExpandedKeys();
    }
    let checkedKeys: string[] = [];
    if (this.control.Root) {
      checkedKeys = this.control.Root.getCheckedKeys();
    }
    this.state = {
      data: this.control.init(this) as UpdateInnerTreeView,
      vcxVersion: -1,
      columns: undefined,
      expandedKeys: expandedKeys,
      checkedKeys: checkedKeys,
      columnIndex: undefined,
      arrow: undefined,
      drag: false,
      width: undefined,
      selectedRowKey: undefined,
      height: 0,
      dragging: false,
      xDragPos: undefined,
      dataSource: null,
      reordered: false,
      deleted: false,
    };
  }

  updatePosition(oldRow: string, newRow: string, oldCol: number, newCol: number): void {
    if (oldRow !== newRow && this.state.selectedRowKey !== newRow) {
      this.setState({ selectedRowKey: newRow }); //pokud se selected nastavoval jen zmenou stavu podle currentbmk, pak nefungoval na prave tlacitko mysi kdy se sice spravne zobrazilo ale tree ukazovalo posledni selektovany radek a opravilo to az po uzavreni popupu.
    }
  }

  updateState(state: UpdateInnerTreeView) {
    if (state.ComputedColumnsVersion !== state.ColumnsVersion && this.state.width > 0) {
      this.control.reCalculateColumnWidths(this.state.width);
      return; // recalculate columns width and call update
    }

    this.setState((prevState: InnerTreeViewState) => {
      let diffs = prevState.clientWidthDiffs;
      if (state.ColumnsVersion != prevState.data.ColumnsVersion) {
        diffs = undefined;
      }
      return { data: state as UpdateInnerTreeView, clientWidthDiffs: diffs };
    });
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
    writeToCSS(this.control.VCX, this.innerTreeViewDiv.current);
  }

  componentWillUnmount() {
    this.ro.unobserve(this.innerTreeViewDiv.current);
    this.control.willUnMount(true);
    this.control = null;
    if (this.hackScroll) {
      this.hackScroll.removeEventListener("wheel", this.handleHackWheel);
    }
  }

  componentDidMount() {
    this.ro = new ResizeObserver((entries, observer) => {
      this.setState({ height: entries[0].contentRect.height, width: entries[0].contentRect.width });

      if (this.state.data.ComputedColumnsVersion && this.state.data.ComputedColumnsVersion === this.state.data.ColumnsVersion) {
        this.setState({ columns: this.convertColumns(this.state.data.ColumnsProportion), dataSource: this.control.Root.children } as InnerTreeViewState);
      }
    });

    this.ro.observe(this.innerTreeViewDiv.current);

    if (this.control.VCX !== this.control.Parent.VCX) {
      writeToCSS(this.control.VCX, this.innerTreeViewDiv.current);
    }
    resolveContextMenu(this.innerTreeViewDiv.current, this.handleContextMenu);
  }

  componentDidUpdate(prevProps: WithContextPlacementProps, prevState: InnerTreeViewState) {
    if (
      this.state.width > 0 &&
      (!this.state.data.ColumnsVersion ||
        this.state.data.ComputedColumnsVersion !== this.state.data.ColumnsVersion ||
        (!this.state.clientWidthDiffs && prevState.width != this.state.width && this.state.data.AutoAdjustWidth))
    ) {
      this.control.reCalculateColumnWidths(this.state.width);
      return;
    }

    let expandedKeys: string[] = undefined;
    let checkedKeys: string[] = undefined;
    if (prevState.data.DataVersion !== this.state.data.DataVersion) {
      expandedKeys = this.control.Root.getExpandedKeys();
      checkedKeys = this.control.Root.getCheckedKeys();
    }

    let newState: InnerTreeViewState = null;
    if (prevState.data.ComputedColumnsVersion !== this.state.data.ComputedColumnsVersion || prevState.clientWidthDiffs !== this.state.clientWidthDiffs) {
      newState = { columns: this.convertColumns(this.state.data.ColumnsProportion) } as InnerTreeViewState;
    }

    if (expandedKeys) {
      newState = Object.assign({}, newState, { expandedKeys: expandedKeys }) as InnerTreeViewState;
    }
    if (checkedKeys) {
      newState = Object.assign({}, newState, { checkedKeys: checkedKeys }) as InnerTreeViewState;
    }

    if (this.state.deleted) {
      this.hoveredColumnIndex = undefined;
      this.setState({ ...newState, dragging: false, deleted: false, xDragPos: undefined });
    } else if (this.state.reordered) {
      this.hoveredColumnIndex = undefined;
      this.setState({ ...newState, dragging: false, reordered: false, xDragPos: undefined });
    }

    if (this.state.selectedRowKey !== prevState.selectedRowKey) {
      const current = document.querySelector(`[data-row-key='${this.state.selectedRowKey}']`);
      if (current) {
        current.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
      }
      newState = { ...newState, columns: this.convertColumns(this.state.data.ColumnsProportion), dataSource: this.control.Root.children };
    }

    if (this.control && this.control.RowBmk) {
      this.updatePosition(undefined, this.control.RowBmk, undefined, this.control.ColNdx);
    }

    if (newState) {
      //newState = { ...newState, dataSource: this.control.Root.children };
      this.setState(
        newState,
        prevState.data.DataVersion !== this.state.data.DataVersion
          ? () => {
              this.setState({ dataSource: undefined }, () => {
                this.setState({ dataSource: this.control.Root.children });
              });
            }
          : undefined
      );
    }

    if (this.state.columns && this.state.columns.length !== 0 && !this.hackScroll && this.innerTreeViewDiv) {
      const result = this.innerTreeViewDiv.current.getElementsByClassName("ant-table-body");
      if (result && result.length === 1) {
        this.hackScroll = result[0];
        this.hackScroll.addEventListener("wheel", this.handleHackWheel);
      }
    }
  }

  private handleHackWheel = (e: WheelEvent) => {
    if (e.shiftKey) return;

    e.preventDefault();
  };

  setColumns = (options: { sizes: DOMRect[]; draggedColumnIndex: number }) => {
    this.columns = options.sizes;
    this.draggedColumnIndex = options.draggedColumnIndex;
    this.setState({ dragging: true });
  };

  private convertColumns(ColumnsProportion: ColumnsProportion): ColumnType<K2TreeViewItem>[] {
    if (ColumnsProportion) {
      const columns: Array<ColumnType<K2TreeViewItem>> = new Array<ColumnType<K2TreeViewItem>>();
      if (ColumnsProportion.length > 0) {
        ColumnsProportion.map((value, index, list) => {
          columns.push(this.createColumn(value, index, list.length));
        });
      }

      return columns;
    }

    return null;
  }

  private calcColumnWidth(proportion: ColumnProportionEm, index: number): number {
    if (!proportion.Width) return 0;

    let diff = 0;
    if (this.state.clientWidthDiffs && index < this.state.clientWidthDiffs.length) {
      diff = this.state.clientWidthDiffs[index];
    }

    return proportion.Width + diff;
  }

  private calcColumnsWidth = () => {
    let tableWidth = 0;

    for (let i = 0; i < this.state.data.ColumnsProportion.length; i++) {
      tableWidth += this.calcColumnWidth(this.state.data.ColumnsProportion[i], i);
    }

    return tableWidth;
  };

  private createColumn(value: ColumnProportionEm, index: number, columns: number): ColumnType<K2TreeViewItem> {
    return {
      // title: (value) ? value.Caption : '',
      dataIndex: index,
      render: (column: CSColumnBase, record: K2TreeViewItem, i: number) => {
        if (record && record.columns && record.columns.length > index) {
          return this.renderNode(record.columns[index], record, value, index);
        }
        return null;
      },

      width: value?.Width ? this.calcColumnWidth(value, index) : 100,
      onHeaderCell: (column: ColumnType<K2TreeViewItem>): HeaderColumnProps => {
        return {
          columnNdx: index,
          columnProportion: value,
          onColumnContextMenu: this.handleHeaderColumnContextMenu,
          onColumnClick: () => {},
          fixed: false,
          vcx: this.control.VCX,
          onColumnResize: this.handleColumnResize,
          isTreeCell: true,
          setColumns: this.setColumns,
          deleteColumn: this.deleteColumn,
          isResizable: true,
          isDraggable: true,
        };
      },
    };
  }

  private handleHeaderColumnContextMenu = (rowIndex: number, colIndex: number) => {
    this.control.contextMenu("", colIndex);
  };

  private handleColumnResize = (colIndex: number, diffX: number, isCompleted: boolean) => {
    let diffs: Array<number>;
    if (isCompleted) {
      if (this.state.data.ColumnsProportion && colIndex < this.state.data.ColumnsProportion.length) {
        if (this.state.clientWidthDiffs) {
          diffs = [...this.state.clientWidthDiffs];
        } else {
          diffs = [];
          this.state.data.ColumnsProportion.map((value, i) => {
            diffs[i] = 0;
          });
        }
      }
      if (diffs) {
        diffs[colIndex] += diffX;
        if (this.state.data.ColumnsProportion[colIndex].Width + diffs[colIndex] <= 10) return; //check minimum
        this.control.setColumnsWidth(diffs, this.state.data.ColumnsProportion);
        this.setState({ clientWidthDiffs: diffs });
      }
    }
  };

  private renderNode(value: CSColumnBase, node: K2TreeViewItem, proportion: ColumnProportionEm, colNdx: number): JSX.Element | string {
    return (
      <div
        className="border-transparent"
        style={{ height: `${this.control.VCX.GridControl.GetRowHeight(1)}px`, borderLeft: colNdx === 0 ? "1px solid transparent" : null }}
        draggable={this.state.data.IsDraggable}
        onDragEnter={(e) => this.handleDragEnter(e, node)}
        onDragStart={(e) => this.handleDragStart(e, node)}
        onDragOver={(e) => this.handleDragOver(e, node)}
        onDragLeave={this.handleDragLeave}
        onDrop={(e) => this.handleDrop(e, node)}
      >
        <K2Cell
          vcx={this.control.VCX}
          text={value.Text}
          glyphId={value.GlyphId}
          glyphId2={(value as CSTreeColumn).StateGlyphId}
          isSelectedText={false}
          isSelectedCell={false}
          isSelectedRow={this.state.selectedRowKey === node.key}
          isCancelled={false}
          isLinkCell={false}
          alignment={proportion.Alignment}
          aligmentText={colNdx === 0 ? TAlignment.taRightJustify : proportion.Alignment}
          condFormatting={value.CondFormatting}
          isFixed={false}
        />
      </div>
    );
  }

  handleDragStart = (e: React.DragEvent, node: K2TreeViewItem) => {
    e.dataTransfer.setData("text/plain", node.key);
    this.draggedNode = node;
  };

  handleDragEnter = (e: React.DragEvent, node: K2TreeViewItem) => {
    if (this.state.dragging && this.draggedNode && this.draggedNode.key === node.key) return;
    if (this.expandedNodes.length > 0) {
      clearTimeout(this.expandedNodes.pop());
    }
    this.rect = e.currentTarget.getBoundingClientRect();
    if (this.expandedNodes.length === 0 && node.Data.HasChildren !== TTreeDataHasChildNodes.chnNo && node.expanded !== true) {
      const delayID = setTimeout(() => {
        this.control.executeExpand(node.key);
        this.expandedNodes.pop();
      }, 1000);

      this.expandedNodes.push(delayID);
    }
  };

  handleDrop = (e: React.DragEvent, node: K2TreeViewItem) => {
    e.preventDefault();
    if (this.state.dragging) return;

    e.currentTarget.className = "border-transparent";

    if ((this.draggedNode && node.key === this.draggedNode.key) || node.parent.key === this.draggedNode.key) return;
    if (this.expandedNodes.length > 0) {
      clearTimeout(this.expandedNodes.pop());
    }

    this.control.executeMove(e.dataTransfer.getData("text/plain"), node.key, this.calcDropPosition(e));
    this.setState({ selectedRowKey: e.dataTransfer.getData("text/plain") });
  };

  draggingParentIntoChildren = (node: K2TreeViewItem): boolean => {
    if (this.draggedNode.key === node.key) {
      return true;
    } else if (this.draggedNode.children && this.draggedNode.children.length > 0) {
      const found = node.parent && node.parent.children.findIndex((childNode: K2TreeViewItem) => childNode.parent.key === this.draggedNode.key);
      if (found === null) {
        return false;
      } else if (found > -1) {
        return true;
      } else {
        return this.draggingParentIntoChildren(node.parent);
      }
    } else {
      return false;
    }
  };

  handleDragOver = (e: React.DragEvent, node: K2TreeViewItem) => {
    if (this.state.dragging) return;
    if (this.draggingParentIntoChildren(node)) {
      this.setState({ selectedRowKey: node.key });
      return;
    }

    e.preventDefault();

    const target = e.currentTarget;
    const position = this.calcDropPosition(e);

    switch (position) {
      case TTreeDataMoveNodeMode.tdmnOnNode:
        target.className = "border-select-node";
        break;
      case TTreeDataMoveNodeMode.tdmnBeforeNode:
        target.className = "border-top";
        break;
      case TTreeDataMoveNodeMode.tdmnAfterNode:
        target.className = "border-bottom";
        break;
      default:
        break;
    }
  };

  private calcDropPosition(e: React.DragEvent): TTreeDataMoveNodeMode {
    if (e.clientY >= Math.floor(this.rect.top) - 1 && e.clientY < this.rect.top + 5) {
      return TTreeDataMoveNodeMode.tdmnBeforeNode;
    } else if (e.clientY > this.rect.bottom - 5 && e.clientY < this.rect.bottom) {
      return TTreeDataMoveNodeMode.tdmnAfterNode;
    } else {
      return TTreeDataMoveNodeMode.tdmnOnNode;
    }
  }

  handleDragLeave = (e: React.DragEvent) => {
    e.currentTarget.className = "border-transparent";
  };

  onExpand = (expanded: boolean, record: K2TreeViewItem) => {
    this.control.executeExpand(record.key);
  };

  onCheck = (record: K2TreeViewItem, selected: boolean, selectedRows: K2TreeViewItem[], nativeEvent: Event) => {
    let key = record?.key;
    // Nedokázal jsem přijít na to, proč v některých případech byl record undefined..
    if (!key) {
      key = (nativeEvent.target as HTMLElement)?.closest("[data-row-key]")?.getAttribute("data-row-key");
    }
    if (key) {
      this.control.executeCheck(key, selected ? TTreeViewNodeCheckState.tvncsChecked : TTreeViewNodeCheckState.tvncsUnChecked);
    }
  };

  onChange = (selectedRowKeys: string[], selectedRows: K2TreeViewItem[], info?: any) => {
    return;
  };

  handleColumnDragOver = (e: React.DragEvent) => {
    e.preventDefault();
  };

  handleDragEnterColumns = (e: React.DragEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
    if (!this.state.dragging) return;
    let xPos = 0;

    if (e.nativeEvent instanceof DragEvent) {
      xPos = (e as React.DragEvent).clientX;
    } else {
      xPos = (e as React.TouchEvent).touches[0].clientX;
    }

    const offsetLeft = e.currentTarget.getBoundingClientRect().left;
    this.columns.map((column, index) => {
      if (xPos < column.right && (index < this.state.data.FixedColumnsCount || index < 1)) return;
      if (xPos > column.left && xPos < column.right) {
        if (this.draggedColumnIndex >= index) {
          this.setState({ xDragPos: column.left - offsetLeft });
          this.hoveredColumnIndex = index;
        } else {
          this.setState({ xDragPos: column.right - offsetLeft });
          this.hoveredColumnIndex = index;
        }
      }
    });
  };

  handleDropColumn = () => {
    if (!this.state.dragging) return;
    this.control.columnMove(this.draggedColumnIndex, this.hoveredColumnIndex, true);
    this.setState({ dragging: false });
  };

  deleteColumn = () => {
    if (!this.draggedColumnIndex || this.draggedColumnIndex === 0) return;

    this.control.columnMove(this.draggedColumnIndex, -1, true);
    this.setState({ dragging: false });
  };

  handleContextMenu = () => {
    this.control.contextMenu("", -1);
  };

  private timeout: any;
  private clickCount = 0;

  render() {
    return (
      <div
        ref={this.innerTreeViewDiv}
        style={StyleHelper(this.control, { flex: "1 1 auto", position: "relative", height: this.state.height + "px" })}
        className={css.tw_inner}
        onDragOver={this.handleColumnDragOver}
        onDragEnter={this.handleDragEnterColumns}
        onDrop={this.handleDropColumn}
        onTouchMove={this.handleDragEnterColumns}
        onTouchEnd={this.handleDropColumn}
        onFocus={this.handleFocus}
        onKeyDown={this.handleKeyDown}
        onWheel={this.handleWheel}
      >
        {this.state.dragging && this.hoveredColumnIndex !== undefined && (
          <div style={{ border: "1px solid", position: "absolute", left: this.state.xDragPos, height: "calc(100% - 24px)", zIndex: 1 }}></div>
        )}
        {this.state.data.DeniedText && (
          <div
            style={{
              position: "absolute",
              width: "99%",
              height: this.control.VCX.sizeMap(20) + "px",
              zIndex: 1,
              top: this.control.VCX.sizeMap(25) + "px",
              backgroundColor: "red",
              color: "white",
              justifyContent: "center",
            }}
          >
            {this.state.data.DeniedText}
          </div>
        )}
        {this.state.columns && this.state.columns.length !== 0 && this.calcColumnsWidth() !== 0 && (
          <Table
            style={{ width: this.calcColumnsWidth() + "px" }}
            className={`${css.tw_table}${this.control.InEditMode ? ` ${css.tw_table_edit}` : ""}`}
            showHeader={this.state.columns.length === 1 ? false : true}
            scroll={{ y: this.state.height }}
            tableLayout="fixed"
            locale={{ emptyText: this.control.Tree.Ncl.NoDataText }}
            expandable={{
              childrenColumnName: "children",
              expandIconColumnIndex: 0,
              onExpand: this.onExpand,
              expandedRowKeys: this.state.expandedKeys,
            }}
            rowSelection={
              this.state.data.CheckMode !== TTreeViewCheckingMode.tvcmNone && {
                hideSelectAll: true,
                onChange: this.onChange,
                onSelect: this.onCheck,
                selectedRowKeys: this.state.checkedKeys,
              }
            }
            size="small"
            dataSource={this.state.dataSource}
            columns={this.state.columns}
            pagination={false}
            rowClassName={(record: K2TreeViewItem) => {
              if (this.state.data.DeniedText) {
                return "antd-denied-text";
              }
            }}
            onRow={(record: K2TreeViewItem) => {
              return {
                tabIndex: 1,
                onClick: (e) => {
                  const target = e.currentTarget;

                  if (!(Context.DeviceInfo.IsIOS || Context.DeviceInfo.IsIPadOS)) {
                    //Normální prohlížeče ne iOS ...
                    if (e.detail >= 2) {
                      this.control.click(record.key, findColumnIndex(target), 2);
                    } else {
                      this.control.click(record.key, findColumnIndex(target), 1);
                    }
                  } else {
                    ///IOS
                    if (this.clickCount <= 0)
                      this.timeout = setTimeout(() => {
                        if (this.clickCount >= 2) {
                          this.control.click(record.key, findColumnIndex(target), 2);
                        } else {
                          this.control.click(record.key, findColumnIndex(target), 1);
                        }
                        this.clickCount = 0;
                      }, 300);

                    this.clickCount++;
                  }
                  e.preventDefault();
                },
                onContextMenu: (e) => {
                  this.control.contextMenu(record.key, findColumnIndex(e.target as HTMLElement));
                  e.preventDefault();
                  e.stopPropagation();
                },
                onFocus: (e) => {
                  this.control.Tree.setActiveControlRequested();
                  e.stopPropagation();
                },
              };
            }}
            components={{
              header: { cell: HeaderColumn },
              body: {
                row: (props: any) => {
                  const { children, ...restProps } = props;
                  if (children.length > 1) {
                    return (
                      <tr {...restProps}>
                        {children.map((child: any, i: number) => {
                          if (this.state.data.CheckMode !== TTreeViewCheckingMode.tvcmNone && i <= 1) {
                            if (i == 1) return;
                            return (
                              <td key={i} className="ant-table-cell">
                                <div className="ant_checkbox_wrap">
                                  {children[0]}
                                  {children[1]}
                                </div>
                              </td>
                            );
                          }
                          return child;
                        })}
                      </tr>
                    );
                  } else return <tr {...restProps}>{children}</tr>;
                },
              },
            }}
          />
        )}
      </div>
    );
  }

  private handleWheel = (e: React.WheelEvent<HTMLDivElement>) => {
    if (e.shiftKey) return;

    if (e.deltaY > 0) {
      this.control.invokeAction(TreeOperations.next);
      e.stopPropagation();
    } else {
      this.control.invokeAction(TreeOperations.prior);
      e.stopPropagation();
    }
  };

  private handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "ArrowUp") {
      this.control.invokeAction(TreeOperations.prior);
      e.stopPropagation();
      e.preventDefault();
      return false;
    }

    if (e.key === "ArrowDown") {
      this.control.invokeAction(TreeOperations.next);
      e.stopPropagation();
      e.preventDefault();
      return false;
    }

    if (e.key === "ArrowRight") {
      this.control.invokeAction(TreeOperations.expand);
      e.stopPropagation();
      e.preventDefault();
      return false;
    }

    if (e.key === "ArrowLeft") {
      this.control.invokeAction(TreeOperations.collaps);
      e.stopPropagation();
      e.preventDefault();
      return false;
    }

    if (e.key === "End") {
      this.control.invokeAction(TreeOperations.lastPage);
      e.stopPropagation();
      e.preventDefault();
      return false;
    }

    if (e.key === "Home") {
      this.control.invokeAction(TreeOperations.firstPage);
      e.stopPropagation();
      e.preventDefault();
      return false;
    }
  };

  private handleFocus = (e: React.FocusEvent<HTMLDivElement>) => {
    this.control.Tree.setActiveControlRequested();
    e.stopPropagation();
  };
}

function findColumnIndex(value: HTMLElement): number {
  let val = value;
  while (val) {
    if (val instanceof HTMLTableCellElement) {
      return val.cellIndex;
    }
    val = val.parentElement;
  }

  return 0;
}
