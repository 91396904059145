import React from "react";
import { Align, CSNclContainerMetadata, FrgtPanelBaseData, UFUpdateControl } from "../../common/communication.base";
import { NclExpander, NclPanel, NclPage, NclTabControl, NclView, NclControlBase, NclContainerBase } from "../../common/components.ncl";
import { GenerateControl } from "../K2GenerateControl";
import { StyleHelper, WithContextPlacementProps } from "../k2hoc";
import { useServerState } from "../hooks";
import css from "./FlowPanel.scss";

export function getBackroundCss(isInPreview: boolean, isInEditMode: boolean): string {
  if (isInPreview) {
    return css.fp_background_preview;
  } else {
    if (isInEditMode) {
      return css.fp_background_edit;
    }
  }
  return css.fp_background;
}

function K2FlowPanel(props: WithContextPlacementProps) {
  const [control, , element] = useServerState<NclContainerBase<CSNclContainerMetadata, UFUpdateControl>, UFUpdateControl, HTMLDivElement>(
    props.controlUID,
    props.vrUID,
    (ctrl) => checkControl(ctrl)
  );
  let clsName: string = props.className && props.className != undefined ? props.className + " " : "";
  clsName += ` ${getBackroundCss(control.isInPreview(), control.InEditMode)}`;

  function checkControl(control: NclControlBase) {
    if (control instanceof NclContainerBase && control.Children) {
      return true;
    }

    return false;
  }

  function getGridTemplateRows() {
    let template = "";

    control.Parts.map((row) => {
      const isColumnTopOrBottomAligned = row.some((column) => {
        if (column.MetaData.Bounds.Align === Align.Top || column.MetaData.Bounds.Align === Align.Bottom) {
          return true;
        }
      });

      const isAnyColumnVisible = row.some((column) => column.State.Visible === true);

      if (!isAnyColumnVisible) return;

      if (isColumnTopOrBottomAligned) {
        template += "min-content ";
      } else {
        if (row[0] instanceof NclExpander && row[0].State.Collapsed) {
          template += "min-content ";
        } else {
          template += "1fr "; // Safari child by jinak výškově přetýkal
        }
      }
    });

    return template;
  }

  if (control.Parent instanceof NclView) {
    clsName += ` ${css.fp_preview}`;
  } else {
    if (control.Parent instanceof NclPage && control.Parent.Parent instanceof NclTabControl && control.Parent.Parent.Btns != null) {
      clsName += ` ${css.fp_main_content}`;
    }
  }

  return (
    <div
      ref={element}
      style={StyleHelper(control, {
        flex: "1 1 auto",
        overflow: (control.MetaData.FrgtData as FrgtPanelBaseData)?.Scroll ? "auto" : "hidden",
        display: control.State.Visible ? "grid" : "none",
        gridTemplateColumns: `repeat(${control.MetaData.Bounds.InteriorBandsCount}, 1fr)`,
        gridTemplateRows: getGridTemplateRows(),
        gap: `${control.VCX.Data.MarginY * 2}px ${control.VCX.Data.MarginX * 2}px`,
        ...props.style,
      })}
      className={"fp " + clsName}
    >
      {control.Parts.map((controls) => {
        let columnStart = 1;

        return controls.map((control) => {
          const columnEnd = columnStart + control.MetaData.Bounds.BandsCount;

          const style = {
            gridColumn: `${columnStart} / ${columnEnd}`,
          };

          columnStart = columnEnd;

          if (control instanceof NclPanel && [Align.Client, Align.Left, Align.Right].includes(control.MetaData.Bounds.Align)) {
            // pokud ma panel zarovnani Client apod. je nutne jej vyjmout z kontextu (pomoci absolutni pozice), aby jeho vysku nezvetsoval vnitrni content
            return (
              <div
                key={control.MetaData.ControlUID}
                style={{
                  ...style,
                  position: control.State.Visible ? "relative" : "absolute", // pokud je Panel neviditelny (suppressed), vyjmu z kontextu tento div, aby neovlivnoval vykreslovani radku v gridu (grid-template-rows)
                  display: control.State.Visible ? "flex" : "none",
                  minHeight: control.ComputedMinHeightWithMargin + "px",
                  width: "100%",
                }}
              >
                {GenerateControl(control, {
                  position: "absolute",
                  height: "100%",
                })}
              </div>
            );
          }

          return GenerateControl(control, { ...style, minHeight: control instanceof NclPanel ? control.ComputedMinHeightWithMargin + "px" : undefined });
        });
      })}
    </div>
  );
}

export default K2FlowPanel;
