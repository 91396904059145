import React from "react";
import { UpdateHeadered } from "../../common/communication.base";
import { NclTreeView } from "../../common/components.ncl";
import { WithContextPlacementProps, StyleHelper } from "../k2hoc";
import { K2Header } from "../Expander/K2Expander";
import css from "./TreeView.scss";
import { K2InnerTreeView } from "./K2InnerTreeView";
import { useServerState } from "../hooks";
import { getAttributes } from "../../common/common";

const K2TreeView = (props: WithContextPlacementProps) => {
  const [control, data, element] = useServerState<NclTreeView, UpdateHeadered, HTMLDivElement>(
    props.controlUID,
    props.vrUID,
    (ctrl) => ctrl instanceof NclTreeView
  );

  const handleFocus = (e: React.FocusEvent<HTMLDivElement>) => {
    control.setActiveControlRequested();
    e.stopPropagation();
  };

  let title = data.Title ? data.Title : "";

  if (data.TitleSuffix) {
    title += " " + data.TitleSuffix;
  }

  return (
    <div
      {...getAttributes(data)}
      ref={element}
      style={StyleHelper(control, { ...props.style, minHeight: control.ComputedMinHeightWithMargin + "px" })}
      className={css.tw}
      onFocus={handleFocus}
    >
      {control.isShowHeader() && <K2Header controlUID={control.Header.MetaData.ControlUID} vrUID={props.vrUID} title={title} />}
      {control.Content && <K2InnerTreeView controlUID={control.Content.Ncl.ControlUID} vrUID={control.getRealizerUID()} />}
    </div>
  );
};

export default K2TreeView;
