import React, { useEffect, useRef, useState } from "react";
import { Html5Qrcode, Html5QrcodeSupportedFormats } from "html5-qrcode";
import { NclCodeReaderDialog } from "../../common/components.ncl";
import { TReaderCodeType } from "../../common/communication.base";

export const CAMERA_NOT_SUPPORTED = "camera not supported";

function getSupportedFormats(ct: TReaderCodeType) {
  const filteredCodeTypes = Object.entries(TReaderCodeType).filter((codeType) => !isNaN(Number(codeType[0])));

  const result = filteredCodeTypes
    .map((filteredType) => {
      const codeType = Number(filteredType[0]);

      if ((ct & codeType) === codeType) {
        if (typeof filteredType[1] !== "string") return;

        const format = filteredType[1].substring(3) as keyof typeof Html5QrcodeSupportedFormats;

        return Html5QrcodeSupportedFormats[format];
      }
    })
    .filter((type): type is Html5QrcodeSupportedFormats => type != null);

  if (result.length === 0) {
    alert(`Unknown reader type: ${ct}`);

    return undefined;
  }

  return result;
}

interface CodeReaderProps {
  control: NclCodeReaderDialog;
}

const K2QRCodeReader = (props: CodeReaderProps) => {
  const html5Qrcode = useRef<Html5Qrcode>();
  const [torchIsActive, setTorchIsActive] = useState(false);
  const [torchButton, setTorchButton] = useState(false);
  const formats = getSupportedFormats(props.control.Ncl.FrgtData.CodeTypes);

  useEffect(() => {
    html5Qrcode.current = new Html5Qrcode("qr_reader", { formatsToSupport: formats, verbose: false });

    const startCamera = async () => {
      try {
        await html5Qrcode.current?.start({ facingMode: "environment" }, { fps: 10 }, onScanSuccess, onScanFailure);

        if (html5Qrcode.current?.getRunningTrackCameraCapabilities().torchFeature().isSupported()) {
          setTorchButton(true);
        }
      } catch (error) {
        console.log(error);

        const qrDiv = document.querySelector("#qr_reader");

        if (qrDiv) {
          qrDiv.textContent = CAMERA_NOT_SUPPORTED;
        }
      }
    };

    startCamera();

    return () => {
      try {
        html5Qrcode.current?.stop();
      } catch (error) {
        console.log(error);
      }
    };
  }, []);

  function onScanSuccess(decodedText: string) {
    props.control.setResult(decodedText);
  }

  function onScanFailure() {
    //
  }

  const handleClick = async () => {
    const torch = html5Qrcode.current?.getRunningTrackCameraCapabilities().torchFeature();

    if (!torch) return;

    if (torchIsActive) {
      await torch.apply(false);
      setTorchIsActive(false);
    } else {
      await torch.apply(true);
      setTorchIsActive(true);
    }
  };

  return (
    <div className="cr_qr">
      <div id="qr_reader"></div>
      {torchButton && (
        <button className={`cr_qr_torch${torchIsActive ? " active" : ""}`} onClick={handleClick}>
          🔦
        </button>
      )}
    </div>
  );
};

export default K2QRCodeReader;
