import * as React from "react";
import { WithContextPlacementProps, K2ComponentState, StyleHelper, AcquireControl } from "./../k2hoc";
import { NclExpander, NclHeader, NclContainerBase, NclView, NclMenuView, NclDataGrid, ClientNclCommandItem } from "./../../common/components.ncl";
import { UpdateControl, UpdateExpander, cJSonFunctionExecuteShortcut } from "./../../common/communication.base";
import K2TruncateText from "./../Text/K2TruncateText";
import K2FlowPanel from "./../FlowPanel/K2FlowPanel";
import { K2LocatorPanel } from "./../LocatorPanel/K2LocatorPanel";
import { AppContext } from "./../../context";
import css from "./Expander.scss";
import { withVCXInCSS, WithVCXinCSSProps } from "../VCX/VCXHelper";
import K2Action from "../Action/K2Action";
import K2ActionSeparator from "../ActionSeparator/K2ActionSeparator";
import K2ToolBar from "../ToolBar/K2ToolBar";

interface HeaderProps extends WithContextPlacementProps {
  title?: string;
  titleSuffix?: string;
  titleSuffixCommandEnabled?: boolean;
}

export class K2Header extends React.PureComponent<HeaderProps, K2ComponentState<UpdateControl>> {
  private control: NclHeader;

  constructor(props: HeaderProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclHeader;
    }) as NclHeader;
    this.state = { data: this.control.init(this) as UpdateControl, vcxVersion: -1 };
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UpdateControl>) => {
      return { data: state as UpdateControl };
    });
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  getHeaderClasses(): string {
    let headerClass = "";
    if (this.control.Headered.isLite()) {
      headerClass = css.ex_header_lite;
    }

    if (this.control.Headered instanceof NclView) {
      headerClass = css.ex_header_view;
    } else if (this.control.Headered instanceof NclMenuView) {
      headerClass = css.ex_header_menu;
    }

    const rule: string = css.ex_header + " " + css.ex_header_font + " " + headerClass;

    return rule;
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  private handleClick = () => {
    this.control.click();
  };

  private handleTitleClick = () => {
    this.control.appendFunction({ Name: cJSonFunctionExecuteShortcut }, true);
  };

  render() {
    const onlyToggleBtn = this.control.RQuickButtons.every((btn) => this.control.Parent instanceof NclDataGrid && btn.Ncl.Name === "_ExpandToggleCOMAND");

    let separator,
      rButtons,
      toolbar,
      locator,
      lButton,
      lButton1: JSX.Element = null;

    if (this.control.LQuickButton) {
      lButton = (
        <K2Action
          controlUID={this.control.LQuickButton.MetaData.ControlUID}
          vrUID={this.control.getRealizerUID()}
          className="no-drag"
          style={{ flex: "none", width: "auto", paddingRight: this.control.VCX.cssSizeMap(4, "px") }}
        />
      );
    }

    if (this.control.LQuickButton1) {
      lButton1 = (
        <K2Action
          controlUID={this.control.LQuickButton1.MetaData.ControlUID}
          vrUID={this.control.getRealizerUID()}
          className="no-drag"
          style={{ flex: "none", width: "auto", paddingRight: this.control.VCX.cssSizeMap(4, "px") }}
        />
      );
    }

    if (this.control.Separator && !onlyToggleBtn) {
      if (!this.control.Headered.isLite() || !(this.control.Headered instanceof NclExpander))
        separator = <K2ActionSeparator controlUID={this.control.Separator.MetaData.ControlUID} vrUID={this.props.vrUID} />;
    }

    if (this.control.RQuickButtons && !onlyToggleBtn) {
      rButtons = (
        <div style={{ flex: "none", overflow: "hidden", paddingRight: `${this.control.VCX.sizeMap(3)}px` }}>
          {this.control.RQuickButtons.map((ctrl, index) => {
            return (
              <K2Action
                controlUID={ctrl.MetaData.ControlUID}
                vrUID={ctrl.getRealizerUID()}
                key={ctrl.MetaData.ControlUID + "_a_" + index}
                style={{ flex: "none", width: "auto" }}
              />
            );
          })}
        </div>
      );
    }

    if (this.control.ToolBar) {
      toolbar = <K2ToolBar controlUID={this.control.ToolBar.MetaData.ControlUID} vrUID={this.props.vrUID} />;
    }

    if (this.control.LocatorPanel) {
      locator = (
        <K2LocatorPanel
          controlUID={this.control.LocatorPanel.MetaData.ControlUID}
          vrUID={this.control.getRealizerUID()}
          style={{ alignSelf: "center", flex: "none", width: "auto" }}
        />
      );
    }

    let classFin: string = this.getHeaderClasses();
    if (this.props.className && this.props.className != undefined) {
      classFin += " " + this.props.className;
    }

    let titleEl: JSX.Element;
    if (this.props.titleSuffixCommandEnabled && this.props.titleSuffix) {
      titleEl = (
        <>
          <K2TruncateText>{this.props.title + " - "}</K2TruncateText>
          <K2TruncateText className={css.ex_header_link} onClick={this.handleTitleClick}>
            {this.props.titleSuffix}
          </K2TruncateText>
        </>
      );
    } else {
      let title = this.props.title;
      if (this.props.titleSuffix) {
        title += ` - ${this.props.titleSuffix}`;
      }
      titleEl = <K2TruncateText>{title}</K2TruncateText>;
    }

    titleEl = (
      <div style={{ flex: "1 0 0%" }} onClick={this.handleClick}>
        {titleEl}
      </div>
    );

    return (
      <div className={classFin} style={{ overflow: "auto", minHeight: this.control.ComputedMinHeight + "px" }}>
        {lButton}
        {lButton1}
        {titleEl}
        <div style={{ flex: "0 1 auto", flexDirection: "row", height: "100%" }} className="no-drag">
          {locator}
          {toolbar}
          {separator}
          {rButtons}
        </div>
      </div>
    );
  }
}

class _Expander extends React.PureComponent<WithVCXinCSSProps, K2ComponentState<UpdateExpander>> {
  static displayName = `K2Expander`;
  private control: NclExpander;
  static contextType = AppContext;
  private element: HTMLDivElement;
  declare context: React.ContextType<typeof AppContext>;

  constructor(props: WithVCXinCSSProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclExpander;
    }) as NclExpander;
    this.state = { data: this.control.init(this) as UpdateExpander, vcxVersion: -1 };
  }

  updateState(state: UpdateExpander) {
    this.setState((prevState: K2ComponentState<UpdateExpander>) => {
      return { data: state as UpdateExpander };
    });
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  componentDidMount(): void {
    if (this.element && this.props.onVCXChanged && this.control.VCX != this.control.Parent?.VCX) {
      this.props.onVCXChanged(this.control.VCX, this.element);
    }
  }

  componentDidUpdate(prevProps: WithContextPlacementProps, prevState: K2ComponentState<UpdateExpander>) {
    if (prevState.data.Collapsed != this.state.data.Collapsed) {
      this.context?.update();

      if (this.control.Parent instanceof NclContainerBase) {
        this.control.Parent.changeVisibleAnyPartsOfContainer();
      }
    }

    if (prevState.vcxVersion !== this.state.vcxVersion) {
      if (this.props.onVCXChanged && this.element) {
        this.props.onVCXChanged(this.control.VCX, this.element);
      }
    }
  }

  render() {
    let classFin: string = css.ex_base;
    if (this.props.className && this.props.className != undefined) {
      classFin += " " + this.props.className;
    }
    let classContent = css.ex_content;
    if (this.control.isInPreview()) {
      classContent += " " + css.ex_content_preview;
    }

    if (this.control.InEditMode) {
      classContent += " " + css.ex_content_edit;
    }
    return (
      <div
        ref={(ref) => (this.element = ref)}
        data-k2-test-id={this.control.MetaData.Name}
        style={StyleHelper(this.control, {
          ...this.props.style,
          minHeight: this.control.ComputedMinHeightWithMargin + "px",
          minWidth: this.control.VCX.ExpanderControl.GetHFHeight() + "px",
        })}
        className={classFin}
      >
        <K2Header
          key={this.control.Ncl.ControlUID + "_header"}
          controlUID={this.control.Header.MetaData.ControlUID}
          vrUID={this.props.vrUID}
          title={this.state.data.Collapsed && this.state.data.CollapsedTitle ? this.state.data.CollapsedTitle : this.state.data.Title}
        />
        <K2FlowPanel controlUID={this.control.Content.MetaData.ControlUID} vrUID={this.control.getRealizerUID()} className={classContent} />
      </div>
    );
  }
}

export const K2Expander = withVCXInCSS(_Expander);
