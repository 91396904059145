import * as React from "react";

import { ElementHtmlAttributes, getAttributes } from "../../common/common";
import { UpdateCommandItem, UpdateControl, TUFActionDisplayStyle, UFUpdateControl } from "../../common/communication.base";
import { NclCommandItem, NclBreaker, NclImage, NclInnerSection } from "../../common/components.ncl";
import SubMenuIndicator from "../SubMenuIndicator/K2SubMenuIndicator";
import { withVCXInCSS, WithVCXinCSSProps } from "../VCX/VCXHelper";
import { K2ComponentState, WithContextPlacementProps, StyleHelper, AcquireControl } from "./../k2hoc";
import K2Img from "../Image/K2Img";
import K2TruncateText from "./../Text/K2TruncateText";
import css from "./RibbonAction.scss";

export class K2ControlBreaker extends React.PureComponent<WithContextPlacementProps, K2ComponentState<UFUpdateControl>> {
  private control: NclBreaker;

  constructor(props: WithContextPlacementProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => ctrl instanceof NclBreaker) as NclBreaker;
    this.state = { data: this.control.init(this) as UFUpdateControl, vcxVersion: -1 };
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UFUpdateControl>) => {
      return { data: state as UFUpdateControl };
    });
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  render() {
    return <div style={StyleHelper(this.control, this.props.style)} className={css.breaker}></div>;
  }
}

interface ActionProps extends WithVCXinCSSProps {
  color?: string;
  captionLinesCount?: number;
  heightIcon?: number;
  size?: number;
  updateVisible?: () => void;
}

class _Action extends React.PureComponent<ActionProps, K2ComponentState<UpdateCommandItem>> {
  private control: NclCommandItem;
  private element: HTMLElement;

  constructor(props: ActionProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclCommandItem;
    }) as NclCommandItem;
    this.state = { data: this.control.init(this) as UpdateCommandItem, vcxVersion: -1 };
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  componentDidMount(): void {
    if (this.element && this.props.onVCXChanged && this.control.VCX != this.control.Parent?.VCX) {
      this.props.onVCXChanged(this.control.VCX, this.element);
    }
  }

  componentDidUpdate(prevProps: Readonly<ActionProps>, prevState: Readonly<K2ComponentState<UpdateCommandItem>>, snapshot?: any): void {
    if (prevState.vcxVersion !== this.state.vcxVersion) {
      if (this.props.onVCXChanged && this.element) {
        this.props.onVCXChanged(this.control.VCX, this.element);
      }
    }
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UpdateCommandItem>) => {
      return { data: state as UpdateCommandItem };
    });

    if (this.props.updateVisible && this.control.Parent instanceof NclInnerSection && state.Visible) {
      this.props.updateVisible();
    }
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  render() {
    const addAttributes: ElementHtmlAttributes = getAttributes(this.state.data);

    let cls = css.ra_base;
    let clsContent = css.ra_content;
    let clsBtn = css.ra_button;
    let clsPlaceholder = css.ra_placeholder;

    switch (this.control.Ncl.FrgtData.DisplayStyle) {
      case TUFActionDisplayStyle.ufadsButtonTile:
        if (this.props.size === 1) {
          clsContent += " " + css.ra_content_line;
          break;
        }
        cls += " " + css.ra_base_tile;
        clsPlaceholder += " " + css.ra_placeholder_tile;
        break;
      case TUFActionDisplayStyle.ufadsButtonLine:
        clsContent += " " + css.ra_content_line;
        break;
      case TUFActionDisplayStyle.ufadsButtonSmall:
        break;
    }

    if (this.props.className) {
      cls += " " + this.props.className;
    }
    if (this.state.data.Checked) {
      clsBtn += " " + css.ra_button_checked;
    }
    if (!this.state.data.Enabled) {
      clsBtn += " " + css.ra_button_disabled;
    }

    let imgStyle: React.CSSProperties;
    if (this.props.heightIcon > 0) imgStyle = { height: this.props.heightIcon, width: this.props.heightIcon };

    return (
      <div ref={(ref) => (this.element = ref)} style={StyleHelper(this.control, Object.assign({ width: "auto" }, this.props.style))} className={cls}>
        <button data-k2-test-id={this.control.MetaData.Name} className={clsBtn} onClick={this.handleClick} {...addAttributes}>
          <div className={clsContent}>
            <div className={clsPlaceholder}>
              {this.state.data.GlyphId && (
                <K2Img
                  style={imgStyle}
                  key={this.control.MetaData.ControlUID + "_icon"}
                  glyphId={this.state.data.GlyphId}
                  vcx={this.control.VCX}
                  strokeColor={this.props.color}
                  nonSvgHeight={this.control.VCX.sizeMap(48)}
                />
              )}
            </div>
            {this.control.Ncl.FrgtData.DisplayStyle != TUFActionDisplayStyle.ufadsButtonSmall && this.state.data.Title && (
              <K2TruncateText key={this.control.MetaData.ControlUID + "_caption"} className={css.ra_caption_font + " " + css.ra_caption}>
                {this.state.data.Title}
              </K2TruncateText>
            )}
          </div>
          {this.state.data.SubMenuIndicatorVisible && <SubMenuIndicator strokeColor={this.props.color} />}
        </button>
      </div>
    );
  }

  private handleClick = () => {
    this.control.executeCommand();
  };
}

export const K2RibbonAction = withVCXInCSS(_Action);
