import React from "react";
import { Orientation } from "../../common/communication.base";

const ActionSeparator = (props: { height?: number | string; width?: number | string; color?: string; orientation?: Orientation }) => {
  let separator;

  if (props.orientation == Orientation.foHorizontal) {
    separator = <div style={{ borderTop: `1px solid ${props.color}`, width: props.width }}></div>;
  } else {
    separator = <div style={{ borderRight: `${props.width}px solid ${props.color}` }}></div>;
  }

  return separator;
};

export default ActionSeparator;
