import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { ClientWidgetUpdate } from "../../../common/communication.base";
import { BaseWidgetProps } from "../ClientWidget";

interface K2ExternalWidgetProps extends BaseWidgetProps {
  data: ClientWidgetUpdate;
  url: string;
}

export const K2ExternalWidget = (props: K2ExternalWidgetProps) => {
  const element = useRef<HTMLIFrameElement>(null);
  const state = useExternal({ url: props.url, iframe: element.current });

  useEffect(() => {
    if (state === "ready") {
      if ((element.current?.contentWindow as any)?.mountWidget) {
        (element.current?.contentWindow as any)?.mountWidget(props.AdditionalField);
      } else {
        console.log("Function mountWidget not found");
      }
    }
  }, [state]);

  useEffect(() => {
    if (state === "ready") {
      if ((element.current?.contentWindow as any)?.renderWidget) {
        (element.current?.contentWindow as any)?.renderWidget(element.current.contentWindow.document.body, props.data);
      } else {
        console.log("Function renderWidget not found");
      }
    }
  }, [state, props.data]);

  useEffect(() => {
    return () => {
      if (state === "ready") {
        if ((element.current?.contentWindow as any)?.unMountWidget) {
          (element.current?.contentWindow as any)?.unMountWidget();
        } else {
          console.log("Function unMountWidget not found");
        }
      }
    };
  }, []);

  return (
    <iframe ref={element}>
      {state === "loading" && <p>Loading...</p>}
      {state === "ready" && <div />}
    </iframe>
  );
};

const useExternal = (props: { url: string; iframe: HTMLIFrameElement }) => {
  const [state, setState] = useState(props.url ? "loading" : "idle");

  useEffect(() => {
    if (!props.url || !props.iframe) {
      setState("idle");
      return;
    }

    const handleScript = (e: any) => {
      setState(e.type === "load" ? "ready" : "error");
    };

    if (props.url.endsWith(".js")) {
      //Pokud načítám JavaScript soubor
      let script = props.iframe.contentWindow.document.querySelector(`script[src="${props.url}"]`) as HTMLScriptElement;

      if (!script) {
        script = props.iframe.contentWindow.document.createElement("script");
        script.type = "application/javascript";
        script.src = props.url;
        script.async = true;
        props.iframe.contentWindow.document.body.appendChild(script);
        script.addEventListener("load", handleScript);
        script.addEventListener("error", handleScript);
      } else {
        setState("ready");
      }

      return () => {
        script.removeEventListener("load", handleScript);
        script.removeEventListener("error", handleScript);
      };
    } else {
      //Pokud načítám např. HTML soubor
      props.iframe.src = props.url;
      props.iframe.addEventListener("load", handleScript);
      props.iframe.addEventListener("error", handleScript);

      return () => {
        props.iframe.removeEventListener("load", handleScript);
        props.iframe.removeEventListener("error", handleScript);
      };
    }
  }, [props.url, props.iframe]);

  return state;
};
