import React from "react";
import { UpdateControl, UFUpdateControl } from "../../common/communication.base";
import { K2ComponentState, WithContextPlacementProps, StyleHelper, AcquireControl } from "../k2hoc";
import { NclLocatorPanel, UFOverAlign } from "../../common/components.ncl";
import { GenerateControl } from "../K2GenerateControl";
import K2Action from "../Action/K2Action";

interface LocatorPanelProps extends WithContextPlacementProps {
  btnColor?: string;
}

export class K2LocatorPanel extends React.PureComponent<LocatorPanelProps, K2ComponentState<UFUpdateControl>> implements UFOverAlign {
  static displayName = `K2LocatorPanel`;
  private control: NclLocatorPanel;
  private element: HTMLElement;

  constructor(props: LocatorPanelProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclLocatorPanel;
    }) as NclLocatorPanel;
    this.state = { data: this.control.init(this) as UFUpdateControl, vcxVersion: -1 };
  }

  getOverRect(): DOMRect {
    const locator = this.getLocatorInput();
    if (locator && locator.getOverRect) {
      return locator.getOverRect();
    }
    if (this.element) return this.element.getBoundingClientRect();

    return undefined;
  }

  private getLocatorInput(): UFOverAlign {
    let result: UFOverAlign = undefined;
    if (this.control) {
      const ctrl = this.control.Children.find((value) => {
        return value && value.MetaData.Name.indexOf("LocatorText") > 0;
      });
      if (ctrl && ctrl.Listener && (ctrl.Listener as UFOverAlign).getOverRect) {
        result = ctrl.Listener;
      }
    }

    return result;
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UFUpdateControl>) => {
      return { data: state as UFUpdateControl };
    });
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  render() {
    return (
      <div style={StyleHelper(this.control, this.props.style)} id={this.control.Ncl.Name} ref={(ref) => (this.element = ref)}>
        {this.control.SwitchBtn && (
          <K2Action
            controlUID={this.control.SwitchBtn.MetaData.ControlUID}
            color={this.props.btnColor}
            vrUID={this.control.getRealizerUID()}
            style={{ width: "auto", height: `${this.control.VCX.InputControl.getInputHeight(1, true, false)}px`, alignSelf: "center" }}
          />
        )}
        {this.control.Children.map((item, index) => {
          return GenerateControl(item, { width: "100%" });
        })}
      </div>
    );
  }
}
