import React, { useEffect, useState } from "react";
import { __ } from "../../appcontext";

export type GoogleMapProps = {
  data: {
    Street: string;
    Country: string;
    DescrNo: string;
    OrientNo: string;
    City: string;
    GPS: string;
  };
  mapLink: string;
  apiKey: string;
};

export enum GeoState {
  gpsStale,
  gpsLoading,
}

const K2GoogleMap = (props: GoogleMapProps) => {
  const [GEOState, setGEOState] = useState<GeoState>(GeoState.gpsStale);

  useEffect(() => {}, [props.data]);

  function getEmbedLink() {
    const base = "https://www.google.com/maps/embed/v1/place";
    if (props.data.GPS) return base + `?key=${props.apiKey}&q=${props.data.GPS}`;
    else return base + `?key=${props.apiKey}&q=${props.data.Street} ${props.data.DescrNo}/${props.data.OrientNo}, ${props.data.City}, ${props.data.Country}`;
  }

  return (
    <>
      <div className="google_map">
        <iframe loading="lazy" allowFullScreen referrerPolicy="no-referrer-when-downgrade" src={getEmbedLink()}></iframe>
      </div>
    </>
  );
};

export default K2GoogleMap;
