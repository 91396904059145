import React from "react";

export const SvgSelect = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      fillRule="evenodd"
      clipRule={"evenodd"}
      strokeLinejoin={"round"}
      strokeMiterlimit={"2"}
    >
      <path
        d="M16,2.586L11.293,7.293L12.707,8.707L15,6.414L15,13L17,13L17,6.414L19.293,8.707L20.707,7.293L16,2.586ZM7.293,11.293L2.586,16L7.293,20.707L8.707,19.293L6.414,17L13,17L13,15L6.414,15L8.707,12.707L7.293,11.293ZM24.707,11.293L23.293,12.707L25.586,15L19,15L19,17L25.586,17L23.293,19.293L24.707,20.707L29.414,16L24.707,11.293ZM15,19L15,25.586L12.707,23.293L11.293,24.707L16,29.414L20.707,24.707L19.293,23.293L17,25.586L17,19L15,19Z"
        fillRule="nonzero"
      />
    </svg>
  );
};

export const SvgStepEnd = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      style={{ fillRule: "evenodd", clipRule: "evenodd", strokeLinejoin: "round", strokeMiterlimit: "1.41421" }}
    >
      <g transform="matrix(1,0,0,1,-576,-144)">
        <g transform="matrix(1,0,0,1,576,144)">
          <path
            d="M32,12C32,5.373 26.627,0 20,0C17.384,0 14.616,0 12,0C5.373,0 0,5.373 0,12C0,14.616 0,17.384 0,20C0,26.627 5.373,32 12,32C14.616,32 17.384,32 20,32C26.627,32 32,26.627 32,20C32,17.384 32,14.616 32,12Z"
            style={{ fill: "rgb(244,86,86)" }}
          />
        </g>
        <g id="surface1" transform="matrix(1,0,0,1,576,144)">
          <path d="M6,6L6,26L26,26L26,6L6,6ZM8,8L24,8L24,24L8,24L8,8Z" style={{ fill: "white", fillRule: "nonzero" }} />
        </g>
        <g transform="matrix(1,0,0,1,96,32)">
          <text
            x="272px"
            y="121.623px"
            style={{ fontFamily: "'SegoeUI-Semilight', 'Segoe UI', sans-serif", fontWeight: 350, fontSize: "13px", fill: "rgb(24,55,106)" }}
          >
            pr
            <tspan x="283.692px 291.145px " y="121.623px 121.623px ">
              oc
            </tspan>
            es běží
          </text>
        </g>
      </g>
    </svg>
  );
};

export const SvgStepStart = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 32 32"
      version="1.1"
      style={{ fillRule: "evenodd", clipRule: "evenodd", strokeLinejoin: "round", strokeMiterlimit: "1.41421" }}
    >
      <g transform="matrix(1,0,0,1,-576,-80)">
        <g transform="matrix(1,0,0,1,576,80)">
          <path
            d="M32,12C32,5.373 26.627,0 20,0C17.384,0 14.616,0 12,0C5.373,0 0,5.373 0,12C0,14.616 0,17.384 0,20C0,26.627 5.373,32 12,32C14.616,32 17.384,32 20,32C26.627,32 32,26.627 32,20C32,17.384 32,14.616 32,12Z"
            style={{ fill: "rgb(61,195,124)" }}
          />
        </g>
        <g id="surface1" transform="matrix(1,0,0,1,576.156,80)">
          <path
            d="M9,5.156L9,26.844L10.531,25.844L25.844,16L10.531,6.156L9,5.156ZM11,8.813L22.156,16L11,23.188L11,8.813Z"
            style={{ fill: "white", fillRule: "nonzero" }}
          />
        </g>
        <g transform="matrix(1,0,0,1,96,-32.2969)">
          <text
            x="272px"
            y="121.623px"
            style={{ fontFamily: "'SegoeUI-Semilight', 'Segoe UI', sans-serif", fontWeight: 350, fontSize: "13px", fill: "rgb(24,55,106)" }}
          >
            pr
            <tspan x="283.692px 291.145px " y="121.623px 121.623px ">
              oc
            </tspan>
            es je uk
            <tspan x="338.708px 346.16px " y="121.623px 121.623px ">
              on
            </tspan>
            čen
          </text>
        </g>
      </g>
    </svg>
  );
};

export const SvgStepTransfer = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      style={{ fillRule: "evenodd", clipRule: "evenodd", strokeLinejoin: "round", strokeMiterlimit: "1.41421" }}
    >
      <g transform="matrix(1,0,0,1,-736,-336)">
        <g transform="matrix(1,0,0,1,80.105,0.296875)">
          <path d="M681.98,361.683L681.98,341.723L662.02,341.723L662.02,361.683L681.98,361.683Z" style={{ fill: "rgb(61,195,124)" }} />
        </g>
      </g>
    </svg>
  );
};

export const SvgStepCase = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      style={{ fillRule: "evenodd", clipRule: "evenodd", strokeLinejoin: "round", strokeMiterlimit: "1.41421" }}
    >
      <g transform="matrix(1,0,0,1,-736,-400)">
        <g transform="matrix(1,0,0,1,80.105,-0.11377)">
          <path d="M686.114,416L672,401.886L657.886,416L672,430.114L686.114,416Z" style={{ fill: "rgb(61,195,124)" }} />
        </g>
      </g>
    </svg>
  );
};

export const SvgStepDistrib = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      style={{ fillRule: "evenodd", clipRule: "evenodd", strokeLinejoin: "round", strokeMiterlimit: "1.41421" }}
    >
      <g transform="matrix(1,0,0,1,-736,-464)">
        <g transform="matrix(1,0,0,1,76.9799,128.297)">
          <path d="M683.98,363.683L683.98,339.723L664.02,351.723L683.98,363.683Z" style={{ fill: "rgb(61,195,124)" }} />
        </g>
      </g>
    </svg>
  );
};

export const SvgStepSubModel = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      style={{ fillRule: "evenodd", clipRule: "evenodd", strokeLinejoin: "round", strokeMiterlimit: "1.41421" }}
    >
      <g transform="matrix(1,0,0,1,-576,-272)">
        <g transform="matrix(1,0,0,1,576,272)">
          <path
            d="M32,12C32,5.373 26.627,0 20,0C17.384,0 14.616,0 12,0C5.373,0 0,5.373 0,12C0,14.616 0,17.384 0,20C0,26.627 5.373,32 12,32C14.616,32 17.384,32 20,32C26.627,32 32,26.627 32,20C32,17.384 32,14.616 32,12Z"
            style={{ fill: "rgb(255,136,0)" }}
          />
        </g>
        <g transform="matrix(1,0,0,1,576,272)">
          <path
            d="M17,8.515L23.485,15L27,15L27,17L23.485,17L17,23.485L17,27L15,27L15,23.485L8.515,17L5,17L5,15L8.515,15L15,8.515L15,5L17,5L17,8.515ZM10.343,16L16,21.657L21.657,16L16,10.343L10.343,16Z"
            style={{ fill: "white" }}
          />
        </g>
        <g transform="matrix(1,0,0,1,96,160)">
          <text
            x="272px"
            y="121.623px"
            style={{ fontFamily: "'SegoeUI-Semilight', 'Segoe UI', sans-serif", fontWeight: 350, fontSize: "13px", fill: "rgb(24,55,106)" }}
          >
            pr
            <tspan x="283.692px 291.145px " y="121.623px 121.623px ">
              oc
            </tspan>
            es je pozastaven
          </text>
        </g>
      </g>
    </svg>
  );
};

export const SvgStepAcknowledge = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      style={{ fillRule: "evenodd", clipRule: "evenodd", strokeLinejoin: "round", strokeMiterlimit: "1.41421" }}
    >
      <g transform="matrix(1,0,0,1,-576,-208)">
        <g transform="matrix(1,0,0,1,576,208)">
          <path
            d="M32,12C32,5.373 26.627,0 20,0C17.384,0 14.616,0 12,0C5.373,0 0,5.373 0,12C0,14.616 0,17.384 0,20C0,26.627 5.373,32 12,32C14.616,32 17.384,32 20,32C26.627,32 32,26.627 32,20C32,17.384 32,14.616 32,12Z"
            style={{ fill: "rgb(0,159,227)" }}
          />
        </g>
        <g id="i" transform="matrix(1,0,0,1,576,208)">
          <path d="M15,26L17,26L17,11L15,11L15,26ZM15,6L17,6L17,8L15,8L15,6Z" style={{ fill: "white", fillRule: "nonzero" }} />
        </g>
        <g transform="matrix(1,0,0,1,96,96.2969)">
          <text
            x="272px"
            y="121.623px"
            style={{ fontFamily: "'SegoeUI-Semilight', 'Segoe UI', sans-serif", fontWeight: 350, fontSize: "13px", fill: "rgb(24,55,106)" }}
          >
            pr
            <tspan x="283.692px 291.145px " y="121.623px 121.623px ">
              oc
            </tspan>
            es je st
            <tspan x="335.007px 342.459px " y="121.623px 121.623px ">
              or
            </tspan>
            nov
            <tspan x="367.266px 373.778px " y="121.623px 121.623px ">
              án
            </tspan>
          </text>
        </g>
      </g>
    </svg>
  );
};

export const SvgCopy = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 48 48"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
    >
      <path d="M18.5,5C15.48,5 13,7.48 13,10.5L13,32.5C13,35.52 15.48,38 18.5,38L34.5,38C37.52,38 40,35.52 40,32.5L40,10.5C40,7.48 37.52,5 34.5,5L18.5,5ZM18.5,8L34.5,8C35.898,8 37,9.102 37,10.5L37,32.5C37,33.898 35.898,35 34.5,35L18.5,35C17.102,35 16,33.898 16,32.5L16,10.5C16,9.102 17.102,8 18.5,8ZM11,10L9.781,10.813C8.668,11.555 8,12.804 8,14.141L8,33.5C8,38.747 12.253,43 17.5,43L30.859,43C32.197,43 33.447,42.332 34.188,41.219L35,40L17.5,40C13.91,40 11,37.09 11,33.5L11,10Z" />
    </svg>
  );
};

export const SvgPaste = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve">
      <g>
        <path d="M30,21l-20,0l0,-10l20,0c0,3.333 0,6.667 0,10Zm-18,-8l0,6l16,0l0,-6c-5.333,0 -10.667,0 -16,0Z" />
        <rect x="2" y="2" width="20" height="2" />
        <rect x="2" y="6" width="20" height="2" />
        <rect x="2" y="28" width="20" height="2" />
        <rect x="2" y="24" width="20" height="2" />
      </g>
    </svg>
  );
};

export const SvgDelete = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve">
      <path d="M24,4C20.704,4 18,6.704 18,10L11.746,10C11.657,9.985 11.567,9.978 11.477,9.979C11.404,9.98 11.331,9.988 11.26,10L7.5,10C7.493,10 7.486,10 7.479,10C6.656,10 5.979,10.677 5.979,11.5C5.979,12.323 6.656,13 7.479,13C7.486,13 7.493,13 7.5,13L10,13L10,38.5C10,41.52 12.48,44 15.5,44L32.5,44C35.52,44 38,41.52 38,38.5L38,13L40.5,13C40.507,13 40.514,13 40.521,13C41.344,13 42.021,12.323 42.021,11.5C42.021,10.677 41.344,10 40.521,10C40.514,10 40.507,10 40.5,10L36.746,10C36.585,9.974 36.421,9.974 36.26,10L30,10C30,6.704 27.296,4 24,4ZM24,7C25.674,7 27,8.326 27,10L21,10C21,8.326 22.326,7 24,7ZM13,13L35,13L35,38.5C35,39.898 33.898,41 32.5,41L15.5,41C14.102,41 13,39.898 13,38.5L13,13ZM20.477,17.979C19.654,17.991 18.988,18.678 19,19.5L19,34.5C19,34.507 19,34.514 19,34.521C19,35.344 19.677,36.021 20.5,36.021C21.323,36.021 22,35.344 22,34.521C22,34.514 22,34.507 22,34.5L22,19.5C22,19.493 22,19.486 22,19.478C22,18.656 21.323,17.978 20.5,17.978C20.492,17.978 20.484,17.978 20.477,17.979ZM27.477,17.979C26.654,17.991 25.988,18.678 26,19.5L26,34.5C26,34.507 26,34.514 26,34.521C26,35.344 26.677,36.021 27.5,36.021C28.323,36.021 29,35.344 29,34.521C29,34.514 29,34.507 29,34.5L29,19.5C29,19.493 29,19.486 29,19.478C29,18.656 28.323,17.978 27.5,17.978C27.492,17.978 27.484,17.978 27.477,17.979Z" />
    </svg>
  );
};
