import React, { useRef } from "react";
import { SessionInfo } from "../../common/communication.base";
import moment from "moment";
import { __ } from "../../appcontext";

/* ====== NASTAVENÍ ========= */
/** Šířka swipu SessionBoxu na mobilu */
const SWIPEWIDTH = 60;
/** Šířka mrtvé zóny  */
const DEADZONEWIDTH = 5;

export interface SessionBoxProps {
  key: number;
  item: SessionInfo;
  closeSession(id: string): void;
  loginToSesion(id: string): void;
}

export const SessionBox = (props: SessionBoxProps) => {
  const sessionBox = useRef<HTMLDivElement>();
  let sessionMoveLastPosition: number;
  let sessionMovePosition = 0;
  let deadZone = false;

  function getDeviceInfo(info: SessionInfo): string {
    if (info) {
      if (info.DeviceInfo) return info.DeviceInfo;
      if (info.OS) return info.OS;
    }

    return __("sessionUnrecognizedDevice");
  }

  function getBrowserImage(browser: string) {
    if (!browser) return undefined;
    browser = browser.toUpperCase();
    if (browser.indexOf("CHROME") >= 0) {
      return "img/chrome.svg";
    } else if (browser.indexOf("FIREFOX") >= 0) {
      return "img/firefox.svg";
    } else if (browser.indexOf("SAFARI") >= 0) {
      return "img/safari.svg";
    }
  }

  function getOSImage(os: string) {
    if (!os) return undefined;
    os = os.toUpperCase();
    if (os.indexOf("WINDOWS") >= 0) {
      return "img/os_windows.svg";
    } else if (os.indexOf("LINUX") >= 0) {
      return "img/os_linux.svg";
    } else if (os.indexOf("IOS") >= 0 || os.indexOf("MAC") >= 0) {
      return "img/os_apple.svg";
    } else if (os.indexOf("ANDROID") >= 0) {
      return "img/os_android.svg";
    }
  }

  function sessionSlideMove(e: React.TouchEvent<HTMLDivElement>) {
    const diff = sessionMoveLastPosition - e.touches[0].clientX;
    if (sessionMovePosition - diff >= SWIPEWIDTH) {
      sessionMovePosition = SWIPEWIDTH;
      sessionBox.current?.classList.add("done"); // Zvětší ikonku
      deadZone = true; //Zapne mrtvou zonu
      setTranslate(sessionMovePosition); //Nastaví konečnou pozici (zde kvůli deadZone)
    } else if (sessionMovePosition - diff <= -SWIPEWIDTH) {
      sessionMovePosition = -SWIPEWIDTH;
      sessionBox.current?.classList.add("done"); // Zvětší ikonku
      deadZone = true; //Zapne mrtvou zonu
      setTranslate(sessionMovePosition); //Nastaví konečnou pozici (zde kvůli deadZone)
    } else {
      sessionMovePosition -= diff;
    }

    if (deadZone && sessionMovePosition <= SWIPEWIDTH - DEADZONEWIDTH && sessionMovePosition >= -(SWIPEWIDTH - DEADZONEWIDTH)) {
      //Odnastaví mrtvou zonu
      deadZone = false;
    }
    if (!deadZone) {
      setTranslate(sessionMovePosition);
      sessionBox.current?.classList.remove("done"); // Původní stav
    }

    sessionMoveLastPosition = e.touches[0].clientX;
  }
  function sessionSlideStart(e: React.TouchEvent<HTMLDivElement>) {
    sessionMoveLastPosition = e.touches[0].clientX;
    sessionBox.current?.classList.remove("transition");
  }
  function sessionSlideEnd(e: React.TouchEvent<HTMLDivElement>) {
    if (sessionMovePosition >= SWIPEWIDTH) {
      props.loginToSesion(props.item.ID);
    } else if (sessionMovePosition <= -SWIPEWIDTH) {
      props.closeSession(props.item.ID);
    } else {
      sessionBox.current?.classList.add("transition"); // Nastaví zpáteční smooth slide
      sessionMovePosition = 0;
      setTranslate(sessionMovePosition);
    }
  }

  function setTranslate(pos: number) {
    if (!sessionBox.current) return;
    sessionBox.current.style.transform = `translateX(${pos}px)`;
  }

  return (
    <div className="select-session-box-wrap">
      <div ref={sessionBox} className="select-session-box">
        <div className="session-state" style={{ backgroundColor: !props.item.IsActiveConnection ? "#FF6464" : "#59E17D" }} />
        <div className="session-info-box" onTouchStart={sessionSlideStart} onTouchMove={sessionSlideMove} onTouchEnd={sessionSlideEnd}>
          <div className="session-info">
            <img src={getOSImage(props.item.OS)} />
            <p className="session-info-txt session-info-txt-main">{getDeviceInfo(props.item)}</p>
          </div>
          <div className="session-info">
            <img src={getBrowserImage(props.item.Browser)} />
            <div className="session-info-txt">
              <p placeholder={__("sessionLoginDateTime")}>{`${moment(props.item.Created).format("DD.MM.YY HH:mm:ss")}`}</p>&nbsp;-&nbsp;
              <p placeholder={__("sessionLastActionDateTime")}>{`${
                props.item.LastUserInteractiveTime
                  ? moment(props.item.LastUserInteractiveTime).format("DD.MM.YY HH:mm:ss")
                  : moment(props.item.Created).format("DD.MM.YY HH:mm:ss")
              }`}</p>
            </div>
          </div>
        </div>
        <div className="session-btns">
          <div style={{ flex: "1 0 auto", margin: "auto", alignItems: "center" }}>
            <button className="button session-btn session-btn-cancel" onClick={() => props.closeSession(props.item.ID)} />
          </div>
          <div style={{ flex: "0 1 0%", borderTop: "1px solid #CFCFCF" }} />
          <div style={{ flex: "1 0 auto", margin: "auto", alignItems: "center" }}>
            <button className="button session-btn session-btn-connect" onClick={() => props.loginToSesion(props.item.ID)} />
          </div>
        </div>
      </div>
      <div className="session-slide session-slide-connect">
        <div className="session-btn-connect"></div>
      </div>
      <div className="session-slide session-slide-cancel">
        <div className="session-btn-cancel"></div>
      </div>
    </div>
  );
};
