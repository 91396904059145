import React, { useEffect, useRef, useState } from "react";
import { HorizontalAlignment, RadioButton, UpdateRadioBox, VerticalAlignment } from "../../common/communication.base";
import { NclRadioBox } from "../../common/components.ncl";
import { useServerState } from "../hooks";
import K2Img from "../Image/K2Img";
import { StyleHelper, WithContextPlacementProps } from "../k2hoc";
import "./RadioBox.scss";

type RadioBoxProps = WithContextPlacementProps

export default function K2RadioBox(props: RadioBoxProps) {
  const [control, data, element] = useServerState<NclRadioBox, UpdateRadioBox, HTMLDivElement>(
    props.controlUID,
    props.vrUID,
    (ctrl) => ctrl instanceof NclRadioBox
  );
  const preventUpdate = useRef(false);
  const [value, setValue] = useState(data.RadioValue);

  useEffect(() => {
    if (value === data.RadioValue) return;

    setValue(data.RadioValue);
  }, [data]);

  function handleChange(e: React.ChangeEvent, index: number, id: number) {
    if (preventUpdate.current) {
      preventUpdate.current = false;

      return;
    }

    setValue(id);
    control.change(index);
  }

  function handleKeyDown(e: React.KeyboardEvent, index: number) {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.stopPropagation();

      if (!control.Ncl.FrgtData.ArrowControl) {
        preventUpdate.current = true;
      }
    }

    if (e.code === "Space") {
      control.change(index);
    }
  }

  function getAlignment() {
    let alignment = "";

    switch (control.Ncl.FrgtData.HorizontalAlignment) {
      case HorizontalAlignment.fhaCenter:
        alignment += " rb_ha_center";
        break;
      case HorizontalAlignment.fhaRight:
        alignment += " rb_ha_right";
        break;
      default:
        break;
    }

    switch (control.Ncl.FrgtData.VerticalAlignment) {
      case VerticalAlignment.fvaCenter:
        alignment += " rb_va_center";
        break;
      case VerticalAlignment.fvaBottom:
        alignment += " rb_va_bottom";
        break;
      default:
        alignment += " rb_va_top";
        break;
    }

    return alignment;
  }

  return (
    <div
      style={StyleHelper(control, props.style)}
      ref={element}
      className={`rb${control.InEditMode ? " rb_edit" : ""}${control.Ncl.FrgtData.ShowFrame ? " rb_framed" : ""}${getAlignment()}`}
      data-k2-test-id={control.MetaData.Name}
    >
      <form className={`rb_form`}>
        {data.RadioButtons.map((button, index) => (
          <K2RadioButton
            key={button.get("Id")}
            button={button}
            control={control}
            checked={button.get("Id") === value}
            index={index}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          />
        ))}
      </form>
    </div>
  );
}

interface RadioButtonProps {
  button: Immutable.Record<RadioButton>;
  control: NclRadioBox;
  checked: boolean;
  index: number;
  onChange: (e: React.ChangeEvent, index: number, id: number) => void;
  onKeyDown: (e: React.KeyboardEvent, index: number) => void;
}

function K2RadioButton(props: RadioButtonProps) {
  return (
    <label
      className={`rb_label${props.button.get("IsCancelled") ? " rb_cancelled" : ""}`}
      htmlFor={`${props.control.Ncl.Name} ${props.button.get("Id")}`}
      onKeyDown={(e) => props.onKeyDown(e, props.index)}
      style={{ gridColumn: `${props.button.get("Position").get("X") + 1}`, gridRow: `${props.button.get("Position").get("Y") + 1}` }}
    >
      <input
        className={`rb_input`}
        id={`${props.control.Ncl.Name} ${props.button.get("Id")}`}
        name={props.control.Ncl.Name}
        type="radio"
        onChange={(e) => props.onChange(e, props.index, props.button.get("Id"))}
        disabled={!props.control.InEditMode}
        checked={props.checked}
      />
      <div className="rb_group">
        {props.button.get("GlyphId") && (
          <K2Img glyphId={props.button.get("GlyphId")} width={props.control.VCX.sizeMap(16)} height={props.control.VCX.sizeMap(16)} />
        )}
        <span className="rb_title">{props.button.get("Title")}</span>
      </div>
    </label>
  );
}
