import React, { useEffect, useRef, useState } from "react";
import { cClientFileType, cJSonFunctionCloseView, CSFile, CSUpdateCommandItem, TOpenOption, UpdateOpenDialogContent } from "../../common/communication.base";
import { ClientNclButton, NclSilentOpenDialog, NclSilentOpenDialogContent } from "../../common/components.ncl";
import { VisualContext } from "../../common/visualContext";
import K2Button from "../Button/K2Button";
import { useServerState } from "../hooks";
import K2Img from "../Image/K2Img";
import { WithContextPlacementProps } from "../k2hoc";
import K2TruncateText from "../Text/K2TruncateText";
import css from "./SilentOpenDialog.scss";
import { __ } from "../../appcontext";

const K2OpenDialogContent = (props: WithContextPlacementProps) => {
  const [control, data, element] = useServerState<NclSilentOpenDialogContent, UpdateOpenDialogContent, HTMLDivElement>(
    props.controlUID,
    props.vrUID,
    (ctrl) => ctrl instanceof NclSilentOpenDialogContent
  );
  const [title, setTitle] = useState("");
  const [filesInMemoryCount, setFilesInMemoryCount] = useState(0);
  let allowMultipleSelect: boolean;
  const input = useRef<HTMLInputElement>();
  const files = useRef<CSFile[]>([]);

  if (control.Parent instanceof NclSilentOpenDialog) {
    allowMultipleSelect = (control.Parent.Ncl.FrgtData.Options & TOpenOption.ofAllowMultiSelect) == TOpenOption.ofAllowMultiSelect;
  }

  useEffect(() => {
    control.RepeatBtn.setExecuteFce(handleRepeat);
    setTitle(allowMultipleSelect ? __("fileSelectFiles") : __("fileSelectFile"));
  }, []);

  useEffect(() => {
    if (!data.Visible) return;

    input.current?.click();
  }, [data.Visible]);

  useEffect(() => {
    if (data.ExpectedFilesCount > 0) {
      setTitle(
        allowMultipleSelect ? `${__("fileUploadFiles")} (${files.current.length - data.ExpectedFilesCount + 1}/${files.current.length})` : __("fileUploadFile")
      );
      control.uploadfile(files.current[files.current.length - data.ExpectedFilesCount]);
      control.RepeatBtn.updateState<CSUpdateCommandItem>({ GlyphId: "wui*OK", Enabled: true });
      control.CloseBtn.updateState({ Enabled: true });
    }

    if (data.ExpectedFilesCount === 0) {
      control.endUpload();
    }
  }, [data.ExpectedFilesCount]);

  const handleRepeat = (ctrl: ClientNclButton) => {
    if (!filesInMemoryCount) if (input.current) input.current.click();
  };

  const handleChange = (e: React.ChangeEvent<HTMLDivElement>) => {
    if (!input.current.files || input.current.files.length <= 0) {
      control.appendFunction({ Name: cJSonFunctionCloseView, Args: null }, true);
    }

    setFilesInMemoryCount(allowMultipleSelect ? input.current.files.length : 1);
    setTitle(allowMultipleSelect ? __("fileLoadFiles") : __("fileLoadFile"));
    control.RepeatBtn.updateState({ Enabled: false });
    control.CloseBtn.updateState({ Enabled: false });
  };

  const addFile = (item: CSFile) => {
    files.current.push(item);

    if (files.current.length === input.current.files.length) {
      control.beginUpload(filesInMemoryCount);
    }
  };

  return (
    <div ref={element} className={css.sod}>
      <input style={{ display: "none" }} type="file" ref={input} multiple={allowMultipleSelect} onChange={handleChange} />
      <p className={css.sod_message}>{title}</p>
      {!filesInMemoryCount && <K2Img glyphId="wui*mt.location.from" height={96} width={96} vcx={control.VCX} style={{ margin: "auto" }} />}
      {filesInMemoryCount > 0 && (
        <div className={css.sod_files}>
          {Array.from(input.current.files).map((file) => (
            <FileItem key={file.name} file={file} addFile={addFile} vcx={control.VCX} />
          ))}
        </div>
      )}
      <div style={{ height: `${control.VCX.InputControl.getInputHeight(2, false, false)}px` }} className={css.sod_buttons}>
        <K2Button controlUID={control.RepeatBtn.MetaData.ControlUID} vrUID={control.getRealizerUID()} fillSvg={true} />
        <K2Button controlUID={control.CloseBtn.MetaData.ControlUID} vrUID={control.getRealizerUID()} fillSvg={true} />
      </div>
    </div>
  );
};

export default K2OpenDialogContent;

interface FileItemProps {
  file: File;
  vcx: VisualContext;
  addFile: (item: CSFile) => void;
}

const FileItem = (props: FileItemProps) => {
  const [fileLoaded, setFileLoaded] = useState(false);

  useEffect(() => {
    const reader = new FileReader();

    reader.onloadend = (ev) => {
      let data = ev.target.result;

      if (data instanceof ArrayBuffer) {
        data = window.btoa(
          new Uint8Array(data).reduce(function (data, byte) {
            return data + String.fromCharCode(byte);
          }, "")
        );
      }

      props.addFile({
        FileName: props.file.name,
        Data: data,
        __type: cClientFileType,
      });

      setFileLoaded(true);
    };

    reader.onabort = (ev) => {
      props.addFile(undefined);
    };

    reader.readAsArrayBuffer(props.file);
  }, []);

  const formatBytes = (bytes: number, decimals: number) => {
    if (bytes == 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals || 2;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  return (
    <div style={{ flex: "0 0 auto", padding: "4px" }}>
      <div style={{ width: "16px", marginRight: "8px" }}>
        {fileLoaded && <K2Img glyphId="wui*commitchange" height={16} width={16} vcx={props.vcx} style={{ flex: "0 0 auto" }} />}
      </div>
      <K2TruncateText style={{ flex: "1 1 auto" }}>{props.file.name}</K2TruncateText>
      {`${formatBytes(props.file.size, 1)}`}
    </div>
  );
};
