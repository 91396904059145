import React from "react";
import { FocusData, UpdateControl } from "../../common/communication.base";
import { NclDataGridContent } from "../../common/components.ncl";
import { useServerState } from "../hooks";
import { StyleHelper, WithContextPlacementProps } from "../k2hoc";
import { K2InnerDataGrid } from "./K2InnerDataGrid";

interface DataGridProps extends WithContextPlacementProps {
  focusData: FocusData;
}

const K2DataGridContent = (props: DataGridProps) => {
  const [control, data, element] = useServerState<NclDataGridContent, UpdateControl, HTMLDivElement>(
    props.controlUID,
    props.vrUID,
    (ctrl) => ctrl instanceof NclDataGridContent
  );

  return (
    <div ref={element} style={StyleHelper(control, { ...props.style, display: "flex", flex: "1 1 auto" })}>
      <K2InnerDataGrid controlUID={control.DataGrid.MetaData.ControlUID} vrUID={control.getRealizerUID()} focusData={props.focusData} />
    </div>
  );
};

export default K2DataGridContent;
