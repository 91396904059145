import React from "react";
import { UFUpdateControl } from "../../common/communication.base";
import { NclGroupBox } from "../../common/components.ncl";
import { useServerState } from "../hooks";
import { GenerateChildrenOfControl } from "../K2GenerateControl";
import { StyleHelper, WithContextPlacementProps } from "../k2hoc";
import K2TruncateText from "../Text/K2TruncateText";
import css from "./GroupBox.scss";

const K2GroupBox = (props: WithContextPlacementProps) => {
  const [control, data, element] = useServerState<NclGroupBox, UFUpdateControl, HTMLDivElement>(
    props.controlUID,
    props.vrUID,
    (ctrl) => ctrl instanceof NclGroupBox
  );

  return (
    <div ref={element} style={StyleHelper(control, { ...props.style, minHeight: control.ComputedMinHeightWithMargin + "px" })} className={css.gb}>
      <div className={`${css.gb_title} ${css.gb_title_font}`}>
        <K2TruncateText>{data.Title}</K2TruncateText>
      </div>
      {GenerateChildrenOfControl(control)}
    </div>
  );
};

export default K2GroupBox;
