import React from "react";
import { UFUpdateControl, Align, VerticalAlignment } from "../../common/communication.base";
import { NclStackPanel, UFNclControlBase } from "../../common/components.ncl";
import { useServerState } from "../hooks";
import { GenerateControl } from "../K2GenerateControl";
import { StyleHelper, WithContextPlacementProps } from "../k2hoc";
import css from "./StackPanel.scss";

const K2StackPanel = (props: WithContextPlacementProps) => {
  const [control, data, element] = useServerState<NclStackPanel, UFUpdateControl, HTMLDivElement>(
    props.controlUID,
    props.vrUID,
    (ctrl) => ctrl instanceof NclStackPanel
  );

  const left: UFNclControlBase[] = [];
  const right: UFNclControlBase[] = [];
  const rest: UFNclControlBase[] = [];

  control.Children.map((control) => {
    if (control.MetaData.Bounds.Align === Align.Left) {
      left.push(control);
    } else if (control.MetaData.Bounds.Align === Align.Right) {
      right.push(control);
    } else {
      rest.push(control);
    }
  });

  const stack = [left, rest, right];

  const getClassName = () => {
    let className = css.sp;

    if (control.isInPreview()) {
      className += ` ${css.sp_in_preview}`;
    }

    switch (control.MetaData.FrgtData.VerticalAlignment) {
      case VerticalAlignment.fvaTop:
        className += ` ${css.sp_va_start}`;
        break;
      case VerticalAlignment.fvaBottom:
        className += ` ${css.sp_va_end}`;
        break;
      case VerticalAlignment.fvaCenter:
        className += ` ${css.sp_va_center}`;
        break;
    }

    return className;
  };

  return (
    <div ref={element} style={StyleHelper(control, props.style)} className={getClassName()}>
      {stack.map((part, stackIndex) => {
        return part.map((control, controlIndex) => {
          return GenerateControl(control, {
            width: "auto",
            marginLeft: stackIndex === 2 && controlIndex === 0 ? "auto" : null, // odsazeni skupiny zarovnane doprava
            flex:
              control.MetaData.Bounds.Align === Align.Left || control.MetaData.Bounds.Align === Align.Right
                ? "0 0 auto"
                : `${control.MetaData.Bounds.BandsCount} 1 auto`,
            minWidth:
              control.MetaData.Bounds.Align === Align.Left || control.MetaData.Bounds.Align === Align.Right
                ? 12 * control.MetaData.Bounds.InteriorBandsCount
                : null,
          });
        });
      })}
    </div>
  );
};

export default K2StackPanel;
