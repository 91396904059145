import React from "react";
import { CSDataItem } from "../../common/communication.base";
import { K2Row } from "./K2Row";
import { compareNumberArray, DGHeaderProps } from "./utils";
import css from "./DataGrid.scss";

interface RowsProps extends DGHeaderProps {
  rows: Array<CSDataItem>;
  dataVersion: number;
  inEditMode: boolean;
  rowHeightMultiplier: number;
  rowHeight: number;
  execute: (commandNumber: number) => void;
}

export class K2Rows extends React.Component<RowsProps, {}> {
  private rows: Map<number, K2Row>;
  static displayName = `K2Rows`;

  constructor(props: RowsProps) {
    super(props);
    this.rows = new Map<number, K2Row>();
  }

  public getRow(index: number): K2Row {
    if (index >= 0 && this.rows && index < this.rows.size) {
      return this.rows.get(index);
    }
    return null;
  }

  shouldComponentUpdate(nextProps: RowsProps, nextState: {}, nextContext: any): boolean {
    if (nextProps.columnsVersion !== this.props.columnsVersion) {
      return true;
    }

    if (nextProps.rowHeightMultiplier !== this.props.rowHeightMultiplier) {
      return true;
    }

    if (nextProps.rowHeight !== this.props.rowHeight) {
      return true;
    }

    if (nextProps.dataVersion !== this.props.dataVersion) {
      return true;
    }

    if (nextProps.inEditMode !== this.props.inEditMode) {
      return true;
    }

    if (nextProps.fixedColumnsCount !== this.props.fixedColumnsCount) {
      return true;
    }

    if (!compareNumberArray(this.props.columnsWidths, nextProps.columnsWidths)) {
      return true;
    }

    return false;
  }

  render() {
    this.rows.clear();

    return (
      <tbody className={css.dg_tbody}>
        {this.props.rows?.map((value: CSDataItem, index: number) => (
          <K2Row
            {...this.props}
            rowNdx={value.Row}
            rowHeightMultiplier={this.props.rowHeightMultiplier}
            rowHeight={this.props.rowHeight}
            key={`Row_${value.Row}`} //_${value.Key}
            data={value}
            vcx={this.props.vcx}
            ref={(ref) => {
              if (ref && ref instanceof K2Row) this.rows.set(index, ref);
            }}
            execute={this.props.execute}
          />
        ))}
      </tbody>
    );
  }
}
