import React, { useEffect } from "react";
import {
  DataActionDecorate,
  DisplayMode,
  HorizontalAlignment,
  Orientation,
  TUFDisplayValueAs,
  TUpdateValueState,
  UpdateDataLabel,
  VerticalAlignment,
} from "../../common/communication.base";
import { NclDataLabel } from "../../common/components.ncl";
import { DecorateResult } from "../../common/visualContext";
import { useServerState } from "../hooks";
import { WithContextPlacementProps, StyleHelper } from "../k2hoc";
import { ViewRealizerManager } from "../../viewrealizer";
import K2Img from "../Image/K2Img";
import K2Text from "../Text/K2Text";
import css from "./DataLabel.scss";
import { useResizeDetector } from "react-resize-detector";
import resolveContextMenu from "../../utils/resolveContextMenu";

const K2DataLabel = (props: WithContextPlacementProps) => {
  const [control, data, element] = useServerState<NclDataLabel, UpdateDataLabel, HTMLDivElement>(
    props.controlUID,
    props.vrUID,
    (ctrl) => ctrl instanceof NclDataLabel
  );
  const vr = ViewRealizerManager.getViewRealizer(props.vrUID);

  const { height, ref } = useResizeDetector();

  useEffect(() => {
    resolveContextMenu(element.current, handleContextMenu);
  }, [element]);

  const handleContextMenu = (e: MouseEvent) => {
    e.preventDefault();
    control.contextMenu();
  };

  const handleClick = () => {
    control.click();
  };

  const handleActionClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    control.actionClick();
  };

  const getSuffixPrefixColor = () => {
    let color: string;

    if (control.Ncl.FrgtData.ValueDisplayMode === DisplayMode.fpdmDecorated || control.Ncl.FrgtData.ValueDisplayMode === DisplayMode.fpdmDecoratedBold) {
      color = control.VCX.getColor(control.VCX.Data.ColorMap.ContentNormalColorFrg);
    } else {
      color = control.VCX.getColor(control.VCX.Data.ColorMap.ContentDecorateColorFrg);
    }

    return color;
  };

  const getActualDecorate = () => {
    if (control.Ncl.HasAction) {
      if (data.LinkEnabled === true) {
        if (data.LinkDecorate !== DataActionDecorate.dadeBase) {
          return control.VCX.ColorMap.getColorsForActionDecorate(data.LinkDecorate, data.LinkEnabled);
        } else {
          return { backColor: control.VCX.getColor(control.VCX.Data.ColorMap.AccentBaseColorBck) } as DecorateResult;
        }
      } else {
        return control.VCX.ColorMap.getColorsForActionDecorate(DataActionDecorate.dadeContentSuppress, data.LinkEnabled);
      }
    }

    return undefined;
  };

  const getVerticalAlignment = () => {
    switch (control.Ncl.FrgtData.VerticalAlignment) {
      case VerticalAlignment.fvaTop:
        return ` ${css.dl_va_top}`;
      case VerticalAlignment.fvaBottom:
        return ` ${css.dl_va_bottom}`;
      default:
        return control.Ncl.DisplayAs === TUFDisplayValueAs.dvaText ? ` ${css.dl_va_center}` : "";
    }
  };

  const getHorizontalAlignment = () => {
    switch (control.Ncl.FrgtData.HorizontalAlignment) {
      case HorizontalAlignment.fhaLeft:
        return css.dl_ha_left;
      case HorizontalAlignment.fhaRight:
        return css.dl_ha_right;
      default:
        return css.dl_ha_center;
    }
  };

  const getLabelClassName = () => {
    let className = css.dl;

    if (control.Ncl.FrgtData.Orientation === Orientation.foHorizontal) {
      className += `${getVerticalAlignment()}`;
    } else {
      className += ` ${css.dl_column}`;
    }

    if (control.Ncl.FrgtData.BottomLine) {
      className += ` ${css.dl_bottom_line}`;
    }

    return className;
  };

  const imageAlignedTopOrBottom = () => {
    if ([VerticalAlignment.fvaBottom, VerticalAlignment.fvaTop].includes(control.Ncl.FrgtData.VerticalAlignment)) return true;

    return false;
  };
  const getLines = (): number => {
    if (vr.isDialog()) {
      return -1;
    }
    return control.Size;
  };

  function getPrefix(): JSX.Element {
    if (data.PrefixGlyphId !== "") {
      return (
        <K2Img
          k2Id={control.MetaData.Name}
          glyphId={data.PrefixGlyphId}
          vcx={control.VCX}
          width={imageAlignedTopOrBottom() ? control.ComputedMinHeight : height}
          height={imageAlignedTopOrBottom() ? control.ComputedMinHeight : height}
          style={{ aspectRatio: imageAlignedTopOrBottom() ? "1" : null }}
          strokeColor={getSuffixPrefixColor()}
        />
      );
    }
    if (data.PrefixText !== "") {
      return (
        <K2Text vcx={control.VCX} displayMode={control.Ncl.FrgtData.ValueDisplayMode} color={getSuffixPrefixColor()}>
          {data.PrefixText}
        </K2Text>
      );
    }
  }
  function getSuffix(): JSX.Element {
    if (data.SuffixGlyphId !== "") {
      return (
        <K2Img
          k2Id={control.MetaData.Name}
          glyphId={data.SuffixGlyphId}
          vcx={control.VCX}
          width={imageAlignedTopOrBottom() ? control.ComputedMinHeight : height}
          height={imageAlignedTopOrBottom() ? control.ComputedMinHeight : height}
          style={{ aspectRatio: imageAlignedTopOrBottom() ? "1" : null }}
          strokeColor={getSuffixPrefixColor()}
        />
      );
    }
    if (data.SuffixText !== "") {
      return (
        <K2Text vcx={control.VCX} displayMode={control.Ncl.FrgtData.ValueDisplayMode} color={getSuffixPrefixColor()}>
          {data.SuffixText}
        </K2Text>
      );
    }
  }

  return (
    <div ref={element} className={getLabelClassName()} style={StyleHelper(control, props.style)} data-k2-test-id={control.MetaData.Name} onClick={handleClick}>
      <K2Text displayMode={control.Ncl.FrgtData.TitleDisplayMode} vcx={control.VCX} className={`${css.dl_title} ${getHorizontalAlignment()}`}>
        {data.Title}
      </K2Text>
      <div className={css.dl_value} style={getLines() === -1 ? { maxHeight: "100%" } : undefined}>
        {getPrefix()}

        <div
          ref={ref}
          onClick={handleActionClick}
          className={`${css.dl_text} ${getHorizontalAlignment()}${control.Ncl.HasAction ? ` ${css.dl_text_link}` : ""}`}
          style={{ opacity: data.TextUpdateValueState !== TUpdateValueState.uvsOK ? 0.6 : null }}
        >
          {control.Ncl.DisplayAs === TUFDisplayValueAs.dvaText ? (
            <K2Text linkDecorate={getActualDecorate()} vcx={control.VCX} displayMode={control.Ncl.FrgtData.ValueDisplayMode} lines={getLines()}>
              {data.Text}
            </K2Text>
          ) : (
            <K2Img
              k2Id={control.MetaData.Name}
              glyphId={data.GlyphId}
              vcx={control.VCX}
              width={imageAlignedTopOrBottom() ? control.ComputedMinHeight : height}
              style={{ aspectRatio: imageAlignedTopOrBottom() ? "1" : null }}
            />
          )}
        </div>
        {getSuffix()}
      </div>
    </div>
  );
};

export default K2DataLabel;
