import * as React from "react";
import { createPortal } from "react-dom";

interface HintProps {
  backgroundColor: string;
  borderColor: string;
  text: string;
  children?: React.ReactNode;
}

interface HintState {
  showTooltip: boolean;
  x: number;
  y: number;
}

export class Hint extends React.Component<HintProps, HintState> {
  private timeout: number;

  constructor(props: HintProps) {
    super(props);
    this.state = { showTooltip: false, x: 0, y: 0 };
  }

  mouseEnter = (e: React.MouseEvent): void => {
    const { clientX, clientY } = e;

    this.timeout = window.setTimeout(() => {
      this.setState({ showTooltip: true, x: clientX, y: clientY + 10 });
    }, 1000);
  };

  mouseLeave = (): void => {
    window.clearTimeout(this.timeout);
    this.setState({ showTooltip: false });
  };

  componentWillUnmount(): void {
    if (this.timeout) {
      window.clearTimeout(this.timeout);
    }
  }

  render(): JSX.Element {
    return (
      <div style={{ overflow: "hidden" }} onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave}>
        {this.state.showTooltip &&
          createPortal(
            <div
              style={{
                pointerEvents: "none",
                position: "absolute",
                zIndex: 2,
                top: this.state.y,
                left: this.state.x,
                backgroundColor: this.props.backgroundColor,
                border: `1px solid ${this.props.borderColor}`,
                padding: "5px",
              }}
            >
              {this.props.text}
            </div>,
            document.body
          )}
        {this.props.children}
      </div>
    );
  }
}
