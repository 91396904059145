import React from "react";
import { useReactFlow } from "reactflow";
import { MenuActions } from "./K2FlowChart";
import { __ } from "../../appcontext";

interface SidebarProps {
  actions: MenuActions[];
  fireActions: MenuActions[];
  zoomLevel: number;
  updateActions: (actions: MenuActions[]) => void;
  updateCtrlKey: (ctrl: boolean) => void;
  handleFireAction: (id: string) => void;
}

function Menubar(props: SidebarProps) {
  const reactFlowInstance = useReactFlow();

  zoomControls[1].text = Math.round(props.zoomLevel * 100) + " %";

  function handleChange(id: string) {
    const newActions = props.actions.map((action) => {
      const newAction = { ...action };
      newAction.checked = false;

      if (action.id === id) {
        newAction.checked = true;
      }

      return newAction;
    });

    props.updateActions(newActions);
  }

  function handleClick(e: React.MouseEvent, id: string) {
    e.preventDefault();

    switch (id) {
      case "zoomIn":
        reactFlowInstance.zoomIn();
        break;
      case "zoomOut":
        reactFlowInstance.zoomOut();
        break;
      default:
        reactFlowInstance.zoomTo(1);
        break;
    }
  }

  return (
    <form className="flc_controls">
      <div>
        {props.actions.map((action) => (
          <React.Fragment key={action.id}>
            <input type="radio" id={action.id} name={action.name} checked={action.checked} onChange={() => handleChange(action.id)} />
            <label htmlFor={action.id} title={action.title} onClick={(e) => props.updateCtrlKey(e.ctrlKey)}>
              <div className="flc_action">{action.image}</div>
            </label>
          </React.Fragment>
        ))}
        <div className="flc_separator"></div>
        {props.fireActions.map((action) => (
          <React.Fragment key={action.id}>
            <div id={action.id} className="flc_control_item" title={action.title} onClick={() => props.handleFireAction(action.id)}>
              <div className="flc_action">{action.image}</div>
            </div>
          </React.Fragment>
        ))}
      </div>
      <div>
        {zoomControls.map((control) => (
          <button key={control.id} className={"flc_zoom_button " + control.className} onClick={(e) => handleClick(e, control.id)} title={control.title}>
            {control.text}
          </button>
        ))}
      </div>
    </form>
  );
}

export default Menubar;

const zoomControls = [
  {
    id: "zoomOut",
    text: "-",
    title: __("flowchartZoomOut"),
    className: "zoom-out",
  },
  {
    id: "zoomReset",
    text: "100 %",
    title: __("flowchartZoomReset"),
    className: "value",
  },
  {
    id: "zoomIn",
    text: "+",
    title: __("flowchartZoomIn"),
    className: "zoom-in",
  },
];

function notifySvg() {
  return (
    <svg
      version="1.1"
      id="notify_1_"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 32 32"
      enableBackground="new 0 0 32 32"
      xmlSpace="preserve"
    >
      <path
        id="notify"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13,24l-6,5v-5H3V4h26v20H13z M27,6H5v16h4v3l3-3h15V6z M7,17h10v2H7
  V17z M7,13h18v2H7V13z M7,9h18v2H7V9z"
      ></path>
    </svg>
  );
}

function gridSvg() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
    >
      <path
        d="M5,5L5,27L27,27L27,5L5,5ZM7,7L12,7L12,12L7,12L7,7ZM14,7L18,7L18,12L14,12L14,7ZM20,7L25,7L25,12L20,12L20,7ZM7,14L12,14L12,18L7,18L7,14ZM14,14L18,14L18,18L14,18L14,14ZM20,14L25,14L25,18L20,18L20,14ZM7,20L12,20L12,25L7,25L7,20ZM14,20L18,20L18,25L14,25L14,20ZM20,20L25,20L25,25L20,25L20,20Z"
        fillRule="nonzero"
      />
    </svg>
  );
}
