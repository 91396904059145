import React from "react";
import { useEffect, useState } from "react";
import { Context } from "../../appcontext";
import { AlphabetMode, UpdateControl } from "../../common/communication.base";
import { ClientNclButton, ClientNclInput, NclVirtualKeyboardDialog } from "../../common/components.ncl";
import K2Button from "../Button/K2Button";
import { useServerState } from "../hooks";
import K2Img from "../Image/K2Img";
import K2Input from "../Input/K2Input";
import { WithContextPlacementProps } from "../k2hoc";
import css from "./VirtualKeyboard.scss";

interface RenderItem {
  value: string;
  ratio: number;
  hidden?: boolean;
}

interface VirtualKeyboardProps extends WithContextPlacementProps {
  mode: AlphabetMode;
}

interface JsonStructure {
  numericArray: RenderItem[][];
  alphabetArray: RenderItem[][];
  upAlphabetArray: RenderItem[][];
  numAlphabetArray: RenderItem[][];
  dialogButtons: RenderItem[];
}

const K2VirtualKeyboard = (props: VirtualKeyboardProps) => {
  const [control, data, element] = useServerState<NclVirtualKeyboardDialog, UpdateControl, HTMLDivElement>(
    props.controlUID,
    props.vrUID,
    (ctrl) => ctrl instanceof NclVirtualKeyboardDialog
  );
  const [fill, setFill] = useState(Context.DeviceInfo.getOrientation() === "landscape");
  const [keys, setKeys] = useState<JsonStructure>();

  useEffect(() => {
    init();
    window.addEventListener("orientationchange", orientationchange);

    return () => {
      window.removeEventListener("orientationchange", orientationchange);
    };
  }, []);

  const init = async () => {
    try {
      const resp = await fetch("./virtualkeyboard_settings.json", { cache: "no-cache" });
      const data: JsonStructure = await resp.json();

      data.numericArray.map((nums) => {
        nums.map((num) => {
          if (num.value === ",") {
            num.hidden = !control.Ncl.FrgtData.IsFloat;
          }
        });
      });

      setKeys(data);
    } catch (error) {
      setKeys(initArrays());
    }
  };

  const orientationchange = (event: Event) => {
    setFill(!fill);
  };

  const renderKeyboard = () => {
    const input = control.getKeyControlByValue(NclVirtualKeyboardDialog.INPUT);

    return (
      <div className={css.vk_content}>
        {input && <K2Input controlUID={input.MetaData.ControlUID} vrUID={input.getRealizerUID()} blockSoftKeyboard={true} />}
        {renderContent()}
        {fill && <div className={css.vk_empty_row} />}
        <div style={getRowStyle()}>{renderKeys(keys.dialogButtons)}</div>
      </div>
    );
  };

  const getRowStyle = () => {
    if (!useImg()) return { flex: 1 };

    return { height: `${control.VCX.InputControl.getInputHeight(2, true, false)}px` };
  };

  const useImg = () => {
    return !fill && control.Ncl.FrgtData.GlyphId;
  };

  const renderImage = () => {
    if (!useImg()) return null;

    return <K2Img glyphId={control.Ncl.FrgtData.GlyphId} vcx={control.VCX} />;
  };

  const renderContent = () => {
    if (control.Ncl.FrgtData.IsNumeric) {
      return keys.numericArray.map((item, index) => (
        <div key={`key_part_${index}`} style={getRowStyle()}>
          {renderKeys(item)}
        </div>
      ));
    } else {
      let array: RenderItem[][];

      switch (props.mode) {
        case AlphabetMode.upperCase:
          array = keys.upAlphabetArray;
          break;
        case AlphabetMode.numeric:
          array = keys.numAlphabetArray;
          break;
        default:
          array = keys.alphabetArray;
          break;
      }

      return array.map((item, index) => (
        <div key={`key_part_${index}`} style={getRowStyle()}>
          {renderKeys(item)}
        </div>
      ));
    }
  };

  const renderKeys = (btns: RenderItem[]) => {
    return btns.map((item, index) => {
      if (!item) return;

      const ctrl = control.getKeyControlByValue(item.value);

      if (ctrl) {
        return (
          <div key={`key_${item.value}`} style={{ flex: `${item.ratio} 1 0%`, visibility: item.hidden === true ? "hidden" : "visible" }}>
            <K2Button controlUID={ctrl.MetaData.ControlUID} vrUID={control.getRealizerUID()} fillSvg={true} />
          </div>
        );
      } else {
        return <div key={`key_empty_${index}`} style={{ flex: `${item.ratio} 1 0%`, visibility: item.hidden === true ? "hidden" : "visible" }} />;
      }
    });
  };

  const initArrays = () => {
    const numericArray = [
      [
        { value: "7", ratio: 2 },
        { value: "8", ratio: 2 },
        { value: "9", ratio: 2 },
      ],
      [
        { value: "4", ratio: 2 },
        { value: "5", ratio: 2 },
        { value: "6", ratio: 2 },
      ],
      [
        { value: "1", ratio: 2 },
        { value: "2", ratio: 2 },
        { value: "3", ratio: 2 },
      ],
      [
        { value: "0", ratio: 2 },
        { value: ",", ratio: 2, hidden: !control.Ncl.FrgtData.IsFloat },
        { value: NclVirtualKeyboardDialog.NUM_CLEAR, ratio: 1 },
        { value: NclVirtualKeyboardDialog.NUM_CLEAR_ALL, ratio: 1 },
      ],
    ];

    const alphabetArray = [
      [
        { value: "1", ratio: 2 },
        { value: "2", ratio: 2 },
        { value: "3", ratio: 2 },
        { value: "4", ratio: 2 },
        { value: "5", ratio: 2 },
        { value: "6", ratio: 2 },
        { value: "7", ratio: 2 },
        { value: "8", ratio: 2 },
        { value: "9", ratio: 2 },
        { value: "0", ratio: 2 },
      ],
      [
        { value: "q", ratio: 2 },
        { value: "w", ratio: 2 },
        { value: "e", ratio: 2 },
        { value: "r", ratio: 2 },
        { value: "t", ratio: 2 },
        { value: "y", ratio: 2 },
        { value: "u", ratio: 2 },
        { value: "i", ratio: 2 },
        { value: "o", ratio: 2 },
        { value: "p", ratio: 2 },
      ],
      [
        { value: undefined, ratio: 1 },
        { value: "a", ratio: 2 },
        { value: "s", ratio: 2 },
        { value: "d", ratio: 2 },
        { value: "f", ratio: 2 },
        { value: "g", ratio: 2 },
        { value: "h", ratio: 2 },
        { value: "j", ratio: 2 },
        { value: "k", ratio: 2 },
        { value: "l", ratio: 2 },
        { value: undefined, ratio: 1 },
      ],
      [
        { value: NclVirtualKeyboardDialog.UPPER_CASE, ratio: 2 },
        { value: "z", ratio: 2 },
        { value: "x", ratio: 2 },
        { value: "c", ratio: 2 },
        { value: "v", ratio: 2 },
        { value: "b", ratio: 2 },
        { value: "n", ratio: 2 },
        { value: "m", ratio: 2 },
        { value: NclVirtualKeyboardDialog.NUM_CLEAR, ratio: 2 },
      ],
      [
        { value: NclVirtualKeyboardDialog.NUM, ratio: 2 },
        { value: ",", ratio: 2 },
        { value: " ", ratio: 12 },
        { value: ".", ratio: 2 },
      ],
    ];

    const upAlphabetArray = [
      [
        { value: "1", ratio: 2 },
        { value: "2", ratio: 2 },
        { value: "3", ratio: 2 },
        { value: "4", ratio: 2 },
        { value: "5", ratio: 2 },
        { value: "6", ratio: 2 },
        { value: "7", ratio: 2 },
        { value: "8", ratio: 2 },
        { value: "9", ratio: 2 },
        { value: "0", ratio: 2 },
      ],
      [
        { value: "Q", ratio: 2 },
        { value: "W", ratio: 2 },
        { value: "E", ratio: 2 },
        { value: "R", ratio: 2 },
        { value: "T", ratio: 2 },
        { value: "Y", ratio: 2 },
        { value: "U", ratio: 2 },
        { value: "I", ratio: 2 },
        { value: "O", ratio: 2 },
        { value: "P", ratio: 2 },
      ],
      [
        { value: undefined, ratio: 1 },
        { value: "A", ratio: 2 },
        { value: "S", ratio: 2 },
        { value: "D", ratio: 2 },
        { value: "F", ratio: 2 },
        { value: "G", ratio: 2 },
        { value: "H", ratio: 2 },
        { value: "J", ratio: 2 },
        { value: "K", ratio: 2 },
        { value: "L", ratio: 2 },
        { value: undefined, ratio: 1 },
      ],
      [
        { value: NclVirtualKeyboardDialog.LOWER_CASE, ratio: 2 },
        { value: "Z", ratio: 2 },
        { value: "X", ratio: 2 },
        { value: "C", ratio: 2 },
        { value: "V", ratio: 2 },
        { value: "B", ratio: 2 },
        { value: "N", ratio: 2 },
        { value: "M", ratio: 2 },
        { value: NclVirtualKeyboardDialog.NUM_CLEAR, ratio: 2 },
      ],
      [
        { value: NclVirtualKeyboardDialog.NUM, ratio: 2 },
        { value: ",", ratio: 2 },
        { value: " ", ratio: 12 },
        { value: ".", ratio: 2 },
      ],
    ];

    const numAlphabetArray = [
      [
        { value: "1", ratio: 2 },
        { value: "2", ratio: 2 },
        { value: "3", ratio: 2 },
        { value: "4", ratio: 2 },
        { value: "5", ratio: 2 },
        { value: "6", ratio: 2 },
        { value: "7", ratio: 2 },
        { value: "8", ratio: 2 },
        { value: "9", ratio: 2 },
        { value: "0", ratio: 2 },
      ],
      [
        { value: "@", ratio: 2 },
        { value: "#", ratio: 2 },
        { value: "$", ratio: 2 },
        { value: "_", ratio: 2 },
        { value: "&", ratio: 2 },
        { value: "-", ratio: 2 },
        { value: "+", ratio: 2 },
        { value: "(", ratio: 2 },
        { value: ")", ratio: 2 },
        { value: "/", ratio: 2 },
      ],
      [
        { value: "=", ratio: 2 },
        { value: "*", ratio: 2 },
        { value: `"`, ratio: 2 },
        { value: "'", ratio: 2 },
        { value: ":", ratio: 2 },
        { value: ";", ratio: 2 },
        { value: "!", ratio: 2 },
        { value: "?", ratio: 2 },
        { value: "%", ratio: 2 },
        { value: NclVirtualKeyboardDialog.NUM_CLEAR, ratio: 2 },
      ],
      [
        { value: NclVirtualKeyboardDialog.ALPHABET, ratio: 2 },
        { value: ",", ratio: 2 },
        { value: " ", ratio: 14 },
        { value: ".", ratio: 2 },
      ],
    ];

    const dialogButtons = [
      { value: NclVirtualKeyboardDialog.OK, ratio: 1 },
      { value: NclVirtualKeyboardDialog.CANCEL, ratio: 1 },
    ];

    return { numericArray, alphabetArray, upAlphabetArray, numAlphabetArray, dialogButtons };
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!(e.key === "Enter")) return;

    const okButton = control.getKeyControlByValue("OK");
    const input = control.getKeyControlByValue("INPUT");

    if (!(okButton instanceof ClientNclButton) || !(input instanceof ClientNclInput)) return;

    control.executorCallback(input.Text)(okButton);
  };

  if (keys == null || !control.State.Visible) return null;

  return (
    <div className={css.vk} onKeyDown={handleKeyDown} data-k2-test-id={control.Ncl.Name}>
      {renderImage()}
      {renderKeyboard()}
    </div>
  );
};

export default K2VirtualKeyboard;
