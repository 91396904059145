import React, { useContext } from "react";
import { VCXContext } from "../../context";
import K2Img from "../Image/K2Img";

interface SubMenuIndicatorProps {
  strokeColor?: string;
  name?: string;
}

function SubMenuIndicator(props: SubMenuIndicatorProps) {
  const vcxContext = useContext(VCXContext);

  return (
    <div
      data-k2-test-id={`${props.name}_SubMenuIndicator`}
      style={{ position: "absolute", bottom: `${vcxContext?.vcx.sizeMap(-5)}px`, right: `${vcxContext?.vcx.sizeMap(-4)}px` }}
    >
      <K2Img glyphId="wui*arrow.down" vcx={vcxContext?.vcx} height={15} width={15} strokeColor={props.strokeColor} />
    </div>
  );
}

export default SubMenuIndicator;
