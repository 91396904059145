import React, { useState } from "react";
import { ChromePicker, ColorResult } from "react-color";
import css from "./ColorPicker.scss";
import { NclColorPicker } from "../../common/components.ncl";
import { useServerState } from "../hooks";
import { UpdateControl } from "../../common/communication.base";
import { WithContextPlacementProps } from "../k2hoc";
import K2Button from "../Button/K2Button";
import { VCXColorMap } from "../../common/visualContext";

export default function K2ColorPicker(props: WithContextPlacementProps) {
  //Hodnoty se používají ve formátu BGR;
  const [control, data, element] = useServerState<NclColorPicker, UpdateControl, HTMLDivElement>(
    props.controlUID,
    props.vrUID,
    (ctrl) => ctrl instanceof NclColorPicker
  );
  const [color, setColor] = useState(VCXColorMap.intToHex(control.Ncl.FrgtData.Color));

  function handleChange(color: ColorResult, event: React.ChangeEvent<HTMLInputElement>) {
    //Přenastaví barvu pro komponentu
    setColor(color.hex);
  }

  function handleChangeComplete(color: ColorResult) {
    //Odešle změnu na server
    control.changeColor(VCXColorMap.hexToInt(color.hex));
  }

  return (
    <div tabIndex={-1} className={css.cp} ref={element}>
      <ChromePicker className="cp_editor" color={color} onChange={handleChange} onChangeComplete={handleChangeComplete} disableAlpha={true} />
      <div className="cp_buttons">
        {control.OK && <K2Button controlUID={control.OK.MetaData.ControlUID} vrUID={props.vrUID} />}
        {control.Cancel && <K2Button controlUID={control.Cancel.MetaData.ControlUID} vrUID={props.vrUID} />}
      </div>
    </div>
  );
}
