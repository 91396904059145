import React from "react";
import { AcquireControl, K2ComponentState, StyleHelper, WithContextPlacementProps } from "../k2hoc";
import { K2Header } from "../Expander/K2Expander";
import { UpdateControl, UpdateGantt } from "../../common/communication.base";
import { NclGantt, NclGanttFooter, NclGanttContent } from "../../common/components.ncl";
import K2ToolBar from "../ToolBar/K2ToolBar";
import { __ } from "../../appcontext";
import K2InnerGantt from "./K2InnerGantt";
export class K2GanttContent extends React.Component<WithContextPlacementProps, K2ComponentState<UpdateControl>> {
  static displayName = `K2GanttContent`;
  private control: NclGanttContent;

  constructor(props: WithContextPlacementProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclGanttContent;
    }) as NclGanttContent;
    this.state = { data: this.control.init(this) as UpdateControl, vcxVersion: -1 };
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UpdateControl>) => {
      return { data: state as UpdateControl };
    });
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  render() {
    if (this.control.GanttInner?.MetaData?.ControlUID === undefined) return;
    return (
      <div style={StyleHelper(this.control, { flex: "1 1 auto", ...this.props.style })}>
        <K2InnerGantt controlUID={this.control.GanttInner.MetaData.ControlUID} vrUID={this.control.getRealizerUID()} />
      </div>
    );
  }
}

export class K2GanttFooter extends React.Component<WithContextPlacementProps, K2ComponentState<UpdateControl>> {
  static displayName = `K2GanttFooter`;
  private control: NclGanttFooter;

  constructor(props: WithContextPlacementProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclGanttFooter;
    }) as NclGanttFooter;
    this.state = { data: this.control.init(this) as UpdateControl, vcxVersion: -1 };
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UpdateControl>) => {
      return { data: state as UpdateControl };
    });
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  render() {
    if (this.control.LeftToolbar.Actions.length <= 0) return;
    return (
      <div style={StyleHelper(this.control, this.props.style)}>
        <K2ToolBar controlUID={this.control.LeftToolbar.MetaData.ControlUID} vrUID={this.control.getRealizerUID()} />
      </div>
    );
  }
}

export class K2Gantt extends React.Component<WithContextPlacementProps, K2ComponentState<UpdateGantt>> {
  private control: NclGantt;
  static displayName = `K2Gantt`;

  constructor(props: WithContextPlacementProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclGantt;
    }) as NclGantt;
    this.state = { data: this.control.init(this) as UpdateGantt, vcxVersion: -1 };
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UpdateGantt>) => {
      return { data: state as UpdateGantt };
    });
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  render() {
    let title = this.state.data.Title ? this.state.data.Title : "";
    if (this.state.data.TitleSuffix) {
      title += " " + this.state.data.TitleSuffix;
    }

    return (
      <div style={StyleHelper(this.control, { ...this.props.style, flexDirection: "column" })}>
        {this.control.isShowHeader() && <K2Header controlUID={this.control.Header?.MetaData?.ControlUID} vrUID={this.props.vrUID} title={title} />}
        <K2GanttContent controlUID={this.control.Content.MetaData.ControlUID} vrUID={this.props.vrUID} />
      </div>
    );
  }
}

export default K2Gantt;

//DEMO: https://www.highcharts.com/gantt/demo/project-management/dark-unica
//MANUAL: https://www.highcharts.com/docs/gantt/gantt-task-dependencies
//API: https://api.highcharts.com/gantt/
//FORUM: https://www.highcharts.com/forum/
//Organizacni struktura: https://www.highcharts.com/blog/tutorials/how-to-use-an-org-chart-more-effectively/
