import React, { useEffect, useRef, useState } from "react";
import { HorizontalAlignment, TKeybInputColorStyle, TUFFrameKind, UpdateKeyboardInput, VerticalAlignment } from "../../common/communication.base";
import { NclKeyboardInput, ClientNclKeyboardInput, NclLocatorPanel } from "../../common/components.ncl";
import { StyleHelper } from "../k2hoc";
import K2Img from "../Image/K2Img";
import css from "./Input.scss";
import { InputHtmlAttributes, InputProps } from "./utils";
import { useServerState } from "../hooks";
import { getAttributes, setCustomAttributes } from "../../common/common";
import K2LabelInput from "./K2LabelInput";
import { USKeyboardMap } from "../../utils/keyboard/USKeyboardMap";
import { Context } from "../../appcontext";

export default function K2KeyboardInput(props: InputProps) {
  const input = useRef<HTMLInputElement>();
  const [control, data, element, vcxVersion, focusData] = useServerState<NclKeyboardInput, UpdateKeyboardInput, HTMLDivElement>(
    props.controlUID,
    props.vrUID,
    (ctrl) => ctrl instanceof NclKeyboardInput
  );

  const [isFocused, setIsFocused] = useState(false);
  // const [isRevealedPassword, setIsRevealedPassword] = useState(false);
  const [cursorPosition, setCursor] = useState(0);
  const lastKeyEvent: any = useRef(null);
  const inputEmmiter = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!control.Ncl.FrgtData.IgnoreFieldValue && input.current) input.current.value = data.Text;

    resizeInput();
  }, [data]);

  useEffect(() => {
    if (focusData.isFocused) {
      setIsFocused(true);
    }
  }, [focusData]);

  useEffect(() => {
    if (isFocused) {
      focus();
    }
  }, [isFocused]);

  useEffect(() => {
    input.current?.setSelectionRange(cursorPosition, cursorPosition);
  }, [cursorPosition]);

  function initRef(element: HTMLInputElement) {
    input.current = element;

    if (control instanceof ClientNclKeyboardInput) {
      control.inputRef = element;
    }
  }

  function change(value: string) {
    control.change(value, false);
    control.accept();
    if (control.Ncl.FrgtData.IgnoreFieldValue && input.current) input.current.value = "";
  }

  function focus() {
    if (!input.current) return;

    if (Context.DeviceInfo.ShowKeyboardOnFocus === "1") {
      input.current.focus();
    } else {
      input.current.inputMode = "none";
      input.current.readOnly = true;
      input.current.focus();
      input.current.readOnly = false;
      input.current.inputMode = control.Ncl.FrgtData.IsPassword === true ? "password" : "text";
    }
  }

  function handleFocus(e: React.FocusEvent<HTMLInputElement>) {
    if ((control.State as UpdateKeyboardInput).ReadOnly) return;
    if (!(control instanceof ClientNclKeyboardInput)) control.setActiveControlRequested();
    setIsFocused(true);
    e.stopPropagation();
    input.current?.setSelectionRange(0, input.current.value.length);
  }

  function handleBlur() {
    setIsFocused(false);
    change(input.current.value);
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if ((control.State as UpdateKeyboardInput).ReadOnly || !input.current) {
      e.preventDefault();
      return;
    }
    lastKeyEvent.current = e.key;

    if (e.code === "Enter" || e.code === "NumpadEnter" || e.key === "Enter" || e.keyCode === 13) {
      change(input.current.value);
    } else {
      //Pokud se vyžaduje anglická klávesnice
      if (control.Ncl.FrgtData.InputAsEnglishUSLocale) {
        const USvalue = KeyboardInput_LocalToUSKeys(e);
        if (USvalue !== null) {
          insertAtCursor(USvalue);
          lastKeyEvent.current = USvalue;
          e.preventDefault();
        }
      }
    }

    if (
      e.key === "Delete" ||
      e.key === "ArrowUp" ||
      e.key === "ArrowDown" ||
      e.key === "ArrowLeft" ||
      e.key === "ArrowRight" ||
      e.key === "z" ||
      e.key === "y" ||
      e.key === "-" ||
      e.code === "NumpadDecimal" ||
      e.key.match(/^[0-9]$/) ||
      e.key === "Backspace"
    ) {
      e.stopPropagation();
    }
  }

  function handleKeyPress(e: React.KeyboardEvent<HTMLInputElement>) {
    if (control.Ncl.FrgtData.InputAsEnglishUSLocale && lastKeyEvent.current !== e.key) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  function refCallBack(el: HTMLInputElement) {
    setCustomAttributes(el, control.Ncl.Attributes);
    input.current = el;
    initRef(el);
  }
  function getPrefix() {
    if (control.Ncl.FrgtData.FrontGlyphId !== "")
      return (
        <K2Img
          className={css.in_keyboard_img_front}
          glyphId={control.Ncl.FrgtData.FrontGlyphId}
          vcx={control.VCX}
          strokeColor={control.VCX.getColor(control.VCX.Data.ColorMap.BaseColorBck1)}
          width={control.VCX.InputControl.getEditHeight(1)}
          height={control.VCX.InputControl.getEditHeight(1)}
        />
      );
    else return;
  }
  function getSuffix() {
    if (control.Ncl.FrgtData.RearGlyphId !== "")
      return (
        <K2Img
          className={css.in_keyboard_img_rear}
          glyphId={control.Ncl.FrgtData.RearGlyphId}
          vcx={control.VCX}
          strokeColor={control.VCX.getColor(control.VCX.Data.ColorMap.BaseColorBck1)}
          width={control.VCX.InputControl.getEditHeight(1)}
          height={control.VCX.InputControl.getEditHeight(1)}
        />
      );
    else return;
  }

  function insertAtCursor(myValue: string) {
    if (!input.current) return;

    const value = input.current.value;
    if (input.current.selectionStart || input.current.selectionStart == 0) {
      const startPos = input.current.selectionStart;
      const endPos = input.current.selectionEnd;
      input.current.value = value.substring(0, startPos) + myValue + value.substring(endPos !== null ? endPos : startPos, value.length);
      setCursor(startPos + 1);
    } else {
      input.current.value = value + myValue;
    }
    resizeInput();
  }

  // Upravuje šířku inputu podle daého obsahu v něm
  function resizeInput() {
    if (!input.current || !inputEmmiter.current) return;

    if (input.current.value.length > 0) {
      if (input.current.getAttribute("type") === "password") {
        inputEmmiter.current.classList.add("password");
      } else {
        inputEmmiter.current.classList.remove("password");
      }
      inputEmmiter.current.innerHTML = input.current.value;
    } else {
      inputEmmiter.current.classList.remove("password");
      inputEmmiter.current.innerHTML = input.current.getAttribute("placeholder") ?? "";
    }

    input.current.style.width = inputEmmiter.current.clientWidth + "px";
  }

  /* State to Class */
  const inputAttributes: InputHtmlAttributes = {
    type: "text",
    id: control.Ncl.Name,
    title: data.Title + ": ",
    ...getAttributes(data),
  };
  const inputClass: string = css.in_input + " in_keyboard_input";
  let labelClass = "";
  let wrapClass = "";
  let prefSufClass = "";

  inputAttributes.autoComplete = "off";
  inputAttributes.placeholder = data.Watermark;

  if (control.Ncl.FrgtData.IsPassword) {
    // inputAttributes.type = isRevealedPassword ? "text" : "password";
    inputAttributes.type = "password";
    inputAttributes.title = undefined;
  }

  if (control.Ncl.FrgtData.ColorStyle === TKeybInputColorStyle.kicsLabel) {
    labelClass += " in_background_none";
  }

  if (control.Ncl.FrgtData.FrameKind === TUFFrameKind.uffkunderLine) {
    labelClass += " in_border_underline";
  } else if (control.Ncl.FrgtData.FrameKind !== TUFFrameKind.uffkNone) {
    control.Ncl.FrgtData.ShowFrame = true;
  }

  if (control.Ncl.FrgtData.HorizontalAlignment === HorizontalAlignment.fhaCenter) {
    wrapClass += " horizontal_center";
  } else if (control.Ncl.FrgtData.HorizontalAlignment === HorizontalAlignment.fhaRight) {
    wrapClass += " horizontal_right";
  }

  if (control.Ncl.FrgtData.VerticalAlignment === VerticalAlignment.fvaTop) {
    wrapClass += " vertical_top";
  } else if (control.Ncl.FrgtData.VerticalAlignment === VerticalAlignment.fvaBottom) {
    wrapClass += " vertical_bottom";
  }

  if (control.Ncl.FrgtData.FrameKind === TUFFrameKind.uffkunderLine) {
    prefSufClass += " keyboard_underline";
    if (control.InEditMode) {
      prefSufClass += " in_edit";
      if (isFocused) {
        prefSufClass += " keyboard_focus";
      }
    }
  }

  /* State to Class end */

  return (
    <div style={StyleHelper(control, props.style)} ref={element} className={css.in_input_base}>
      <K2LabelInput
        titleVisible={false}
        title={data.Title}
        errorsCount={0}
        warningsCount={0}
        focused={isFocused}
        inEditMode={control.InEditMode}
        modified={data.Modified}
        readOnly={data.ReadOnly}
        vcx={control.VCX}
        frgtData={control.Ncl.FrgtData}
        id={control.Ncl.Name}
        className={labelClass}
      >
        <label className={"in_keyboard_input_wrap" + wrapClass}>
          {control.Ncl.FrgtData.BackGlyphId !== "" && (
            <K2Img className={css.in_keyboard_img_center} glyphId={control.Ncl.FrgtData.BackGlyphId} vcx={control.VCX} />
          )}
          <div className={"in_keyboard_prefix_suffix" + prefSufClass}>
            {getPrefix()}
            <input
              data-k2-test-id={control.MetaData.Name}
              className={inputClass}
              ref={refCallBack}
              onKeyDown={handleKeyDown}
              onKeyPress={handleKeyPress}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onInput={resizeInput}
              {...inputAttributes}
            />
            <span style={{ visibility: "hidden", position: "absolute" }} className={inputClass} ref={inputEmmiter}></span>
            {getSuffix()}
          </div>
        </label>
        {/* {control.Ncl.FrgtData.IsPassword && (
          <button type="button" className="button" style={{ border: "none", backgroundColor: "transparent", minWidth: "min-content" }} onClick={revealPassword}>
            <K2Img
              width={control.VCX.MinRowHeight}
              height={control.VCX.MinRowHeight}
              glyphId={isRevealedPassword ? "wui*eye.no" : "wui*eye"}
              vcx={control.VCX}
              strokeColor={control.VCX.getColor(control.VCX.Data.ColorMap.BaseColorBck1)}
            />
          </button>
        )} */}
      </K2LabelInput>
    </div>
  );
}

export function KeyboardInput_LocalToUSKeys(e: React.KeyboardEvent<HTMLInputElement>) {
  let char: string = null;
  if (e.shiftKey == false) {
    if (hasKey(USKeyboardMap.default, e.code)) {
      char = USKeyboardMap.default[e.code];
    }
  } else if (e.shiftKey == true) {
    if (hasKey(USKeyboardMap.shift, e.code)) {
      char = USKeyboardMap.shift[e.code];
    }
  }
  if (e.getModifierState("CapsLock") && char !== null) {
    char = char.toUpperCase();
    if (e.shiftKey === true) {
      char = char.toLowerCase();
    }
  }

  return char;
}

function hasKey<O>(obj: object, key: PropertyKey): key is keyof object {
  return key in obj;
}
