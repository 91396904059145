import React, { useEffect, useRef } from "react";
import K2HeaderColumn from "./K2HeaderColumn";
import { DGHeaderProps } from "./utils";
import css from "./DataGrid.scss";
import { OrderByItem } from "../../common/communication.base";
import { NclQuickFilter } from "../../common/components.ncl";
import K2QuickFilter from "./K2QuickFilter";
import K2ColGroup from "./K2ColGroup";

interface HeaderProps extends DGHeaderProps {
  orderBy: Array<OrderByItem>;
  quickFilter: NclQuickFilter;
  width: number;
  scrollLeft: number;
}

const K2DGHeader = (props: HeaderProps) => {
  const tableHeaderRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!tableHeaderRef.current) return;

    tableHeaderRef.current.scrollLeft = props.scrollLeft;
  }, [props.scrollLeft]);

  return (
    <div ref={tableHeaderRef} className="dg_header_wrapper">
      <table className={css.dg_header} style={{ width: props.width + "px" }}>
        {props.columnsProportion && (
          <K2ColGroup
            columnsProportion={props.columnsProportion}
            fixedColumnsCount={props.fixedColumnsCount}
            columnsWidths={props.columnsWidths}
            columnsVersion={props.columnsVersion}
          />
        )}
        <thead>
          <tr className={`${css.dg_header_row_base} ${css.dg_header_row}`}>
            {props.columnsProportion.map((value, index, list) => {
              const isFixed = index < props.fixedColumnsCount;
              const orderBy = props.orderBy.find((item) => {
                return item.ColNdx === index;
              });

              return (
                <K2HeaderColumn
                  fixed={index < props.fixedColumnsCount}
                  columnNdx={index}
                  orderByCount={props.orderBy.length}
                  orderBy={orderBy ? orderBy.OrderByNdx : undefined}
                  onColumnClick={props.onColumnClick}
                  onColumnContextMenu={props.onColumnContextMenu}
                  onColumnResize={props.onColumnResize}
                  key={value.Caption + index.toString() + "_hc"}
                  columnProportion={value}
                  vcx={props.vcx}
                  lastColumn={index === list.length - 1}
                  setColumns={props.setColumns}
                  deleteColumn={props.deleteColumn}
                  columnOffset={props.getFixedColumnOffset(props.columnsWidths, index, isFixed)}
                  editingColumns={props.editingColumns}
                  isDraggable={props.isDraggable}
                  isResizable={props.isResizable}
                />
              );
            })}
          </tr>

          {props.quickFilter && (
            <K2QuickFilter
              vrUID={props.quickFilter.getRealizerUID()}
              controlUID={props.quickFilter.MetaData.ControlUID}
              columnsProportion={props.columnsProportion}
              fixedColumnsCount={props.fixedColumnsCount}
              columnsWidths={props.columnsWidths}
              columnsVersion={props.columnsVersion}
              getFixedColumnOffset={props.getFixedColumnOffset}
            />
          )}
        </thead>
      </table>
    </div>
  );
};

export default K2DGHeader;
