import React, { useRef } from "react";
import { CondFormattingStyle, MFMeasure, MFValue, TAlignment, TUpdateValueState } from "../../common/communication.base";
import { WithVCXProps } from "../k2hoc";
import K2Img from "../Image/K2Img";
import { K2MFText } from "../MultiFormatText/K2MultiFormatText";
import K2TruncateText from "../Text/K2TruncateText";
import { getTextAlign } from "./utils";
import css from "./DataGrid.scss";
import { ColumnBaseHelper } from "../Input/utils";
import { getTextDecoration } from "../k2hoc";

interface CellProps extends WithVCXProps {
  text?: string;
  multiTexts?: Array<MFValue>;
  measure?: MFMeasure;
  glyphId?: string;
  glyphId2?: string;
  isSelectedText?: boolean;
  isSelectedCell: boolean;
  isSelectedRow: boolean;
  isCancelled: boolean;
  isFixed: boolean;
  isLinkCell: boolean;
  condFormatting: CondFormattingStyle;
  alignment: TAlignment;
  aligmentText?: TAlignment;
  isDenied?: TUpdateValueState;
  rowHeightMultiplier?: number;
  execute?: (commandNumber: number) => void;
}

const asterisk = `<svg version="1.1" id="starmark" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 32 32" enable-background="new 0 0 32 32"><path id="starmark_1_" d="M13,29h2h2h2v-2v-5.8l5,2.9l1.8,1l1-1.8l1-1.8l1-1.7l-1.7-1L22,16l5-2.9l1.7-1l-1-1.7l-1-1.8l-1-1.8 l-1.8,1l-5,2.9V5V3h-2h-2h-2v2v5.8L8,7.9l-1.8-1l-1,1.8l-1,1.8l-1,1.7l1.7,1l5,2.9l-5,2.9l-1.7,1l1,1.7l1,1.8l1,1.8l1.8-1l5-2.9V27 V29L13,29z"></path></svg>`;
const questionMark = `<svg version="1.1" id="questionmark" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 32 32" enable-background="new 0 0 32 32"><path id="questionmark_1_" d="M17,22h-2v-2c0-2.2,1.8-4,4-4c1.1,0,2-0.9,2-2v-3c0-1.1-0.9-2-2-2h-4c-1.1,0-2,0.9-2,2v2h-2v-2 c0-2.2,1.8-4,4-4h4c2.2,0,4,1.8,4,4v3c0,2.2-1.8,4-4,4c-1.1,0-2,0.9-2,2V22z M17,24h-2v2h2V24z"></path></svg>`;

const K2Cell = (props: CellProps) => {
  const getClassName = () => {
    let className = css.dg_cell;

    if (props.isSelectedCell) {
      className += ` ${css.dg_cell_selected}`;
    }

    if (props.isSelectedRow) {
      className += ` dg_row_selected`;
    }

    if (props.isCancelled) {
      className += ` ${css.dg_cell_cancelled}`;
    }

    if (props.condFormatting !== undefined) {
      className += ` dg_cell_colored`;
    }

    return className;
  };

  const isTextEmpty = () => {
    return props.text == undefined && props.multiTexts == undefined;
  };

  const textBackColor = props.isSelectedText ? props.vcx.getColor(props.vcx.Data.ColorMap.AccentBaseColorBck) : "";
  const textColor = props.isSelectedText ? props.vcx.getColor(props.vcx.Data.ColorMap.AccentBaseColorFrg) : "";
  let content: Array<JSX.Element> | JSX.Element;
  let useMultiText = false;

  if (props.multiTexts && props.multiTexts.length > 0) {
    content = <K2MFText texts={props.multiTexts} measure={props.measure} vcx={props.vcx} execute={props.execute} />;
    useMultiText = true;
  } else {
    const text = (
      <K2TruncateText
        key="txt"
        keyboardAccelerator={false}
        textBackColor={textBackColor}
        textColor={textColor}
        style={{
          whiteSpace: props.rowHeightMultiplier > 1 ? "normal" : "nowrap",
          textAlign: props.glyphId && isTextEmpty() ? "center" : getTextAlign(props.alignment),
          width: props.glyphId && isTextEmpty() ? "auto" : "100%",
        }}
        line={props.rowHeightMultiplier}
      >
        {props.text?.replace(/\n|\t/g, "")}
      </K2TruncateText>
    );

    const img = props.glyphId && (
      <K2Img
        key="txtimg"
        glyphId={props.glyphId}
        vcx={props.vcx}
        height={props.vcx.GridControl.GetRowHeightWithoutMargin(props.rowHeightMultiplier)}
        style={{ width: "auto", minWidth: props.vcx.GridControl.GetRowHeightWithoutMargin(props.rowHeightMultiplier) }}
      />
    );

    const img2 = props.glyphId2 && (
      <K2Img
        key="txtimg2"
        glyphId={props.glyphId2}
        vcx={props.vcx}
        height={props.vcx.GridControl.GetRowHeightWithoutMargin(props.rowHeightMultiplier)}
        style={{ width: "auto", minWidth: props.vcx.GridControl.GetRowHeightWithoutMargin(props.rowHeightMultiplier) }}
      />
    );

    const alText = props.aligmentText ? props.aligmentText : props.alignment;
    if (img2) {
      content = [img2, img, text];
    } else {
      if (alText === TAlignment.taLeftJustify) {
        content = [text, img];
      } else if (alText === TAlignment.taRightJustify) {
        content = [img, text];
      } else {
        content = [text, img];
      }
    }
  }

  if (props.isDenied) {
    content = (
      <div
        style={{
          backgroundImage: `url('data:image/svg+xml;utf8,${props.isDenied === TUpdateValueState.uvsRefuseByPerm ? asterisk : questionMark}')`,
          width: "100%",
          height: "90%",
          opacity: 0.3,
        }}
      ></div>
    );
  }

  function setLinkCSS(e: Element, set: boolean) {
    if (set) {
      if (!e.classList.contains(css.dg_cell_link)) {
        e.classList.add(css.dg_cell_link);
      }
    } else {
      if (e.classList.contains(css.dg_cell_link)) {
        e.classList.remove(css.dg_cell_link);
      }
    }
  }

  function handleMouseLeave(e: React.MouseEvent) {
    setLinkCSS(e.currentTarget, false);
  }

  function handleMouseEnter(e: React.MouseEvent) {
    if (!e.ctrlKey) return;
    setLinkCSS(e.currentTarget, true);
  }

  function handleMouseMove(e: React.MouseEvent) {
    setLinkCSS(e.currentTarget, e.ctrlKey);
  }

  return (
    <div
      onMouseEnter={props.isLinkCell ? handleMouseEnter : undefined}
      onMouseLeave={props.isLinkCell ? handleMouseLeave : undefined}
      onMouseMove={props.isLinkCell ? handleMouseMove : undefined}
      style={{
        backgroundColor: ColumnBaseHelper.getBackgroundColor(props.condFormatting, props.isSelectedRow, props.isFixed, props.vcx),
        color: ColumnBaseHelper.getForegroundColor(props.condFormatting, props.isSelectedRow, props.isFixed, props.vcx),
        fontWeight: ColumnBaseHelper.IsBold(props.condFormatting) ? "bold" : "normal",
        fontStyle: ColumnBaseHelper.IsItalic(props.condFormatting) ? "italic" : "normal",
        textDecoration: getTextDecoration(ColumnBaseHelper.IsStrike(props.condFormatting), ColumnBaseHelper.IsUnderline(props.condFormatting)),
      }}
      className={getClassName()}
    >
      {content}
    </div>
  );
};

export default K2Cell;
