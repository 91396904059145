import React, { useEffect, useRef, useState } from "react";
import { Context } from "../../appcontext";
import { UpdateInput, TUFDisplayValueAs, TAcceptMode, HorizontalAlignment, TNclInputType } from "../../common/communication.base";
import { NclInput, EditCheckValueResult, InputSelectionText, ClientNclInput, NclLocatorPanel } from "../../common/components.ncl";
import { StyleHelper } from "../k2hoc";
import K2Img from "../Image/K2Img";
import K2InnerInput from "./K2InnerInput";
import K2InnerSimpleInput from "./K2InnerSimpleInput";
import K2LabelInput from "./K2LabelInput";
import css from "./Input.scss";
import K2ToolBar from "../ToolBar/K2ToolBar";
import { ColumnBaseHelper, InputProps } from "./utils";
import { getTextDecoration } from "../k2hoc";
import { useServerState } from "../hooks";
import { getTimeByFormat } from "../../common/common";
import resolveContextMenu from "../../utils/resolveContextMenu";
import { ApplicationBase } from "../../app";
import { FormatUtils } from "../../common/formatUtils";

enum TextPosition {
  beforeCursor,
  afterCursor,
  selected,
}

export default function K2Input(props: InputProps) {
  const input = useRef<HTMLInputElement | HTMLTextAreaElement | null>(null);
  const timer = useRef(0);
  const [control, data, element, vcxVersion, focusData] = useServerState<NclInput, UpdateInput, HTMLDivElement>(
    props.controlUID,
    props.vrUID,
    (ctrl) => ctrl instanceof NclInput
  );
  const innerElement = useRef<HTMLDivElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isRevealedPassword, setIsRevealedPassword] = useState(false);
  const [position, setPosition] = useState(-1);
  const [positionVersion, setPositionVersion] = useState(-1); // force caret position change in case of previously changed position by mouse click or keyboard key
  const [value, setValue] = useState("");
  const invalidDate = useRef(false);
  /** Slouží k nastavení pozice selectu po Focusu */
  const lastSelectedPosition = useRef<{ start: number; end: number }>({ start: 0, end: 0 });
  control.addListenerProp({ getOverRect });

  useEffect(() => {
    resolveContextMenu(innerElement.current, handleContextMenu);
  }, []);

  useEffect(() => {
    setValue(getText());

    if (control.Position >= 0) {
      setPosition(control.Position);
    }
  }, [data]);

  useEffect(() => {
    if (focusData.isFocused) {
      if (control.Parent instanceof NclLocatorPanel) {
        input.current?.focus();
      } else {
        setIsFocused(true);
      }
    }
  }, [focusData]);

  useEffect(() => {
    if (position >= 0) {
      input.current?.setSelectionRange(position, position);
    }
  }, [position, positionVersion]);

  function getOverRect() {
    let result = null;

    if (input.current) {
      result = input.current.getBoundingClientRect();
    }

    if (!result && element.current) {
      result = element.current.getBoundingClientRect();
    }

    return result;
  }

  function handleContextMenu(e: MouseEvent) {
    if (Context.DeviceInfo.IndependentFormatMode) return;

    e.preventDefault();
    showContextMenu();
  }

  function getText(): string {
    let newText = data.Text;

    if (Context.DeviceInfo.IndependentFormatMode) {
      invalidDate.current = false;

      // 0000-00-00 je nepodporovany format pro input typu date (Chrome zobrazi dd.mm.rrrr, Safari aktualni datum)
      if (
        (newText.includes("0000") || newText === "") &&
        [TNclInputType.nitDate, TNclInputType.nitDateTime, TNclInputType.nitTime].includes(control.State.InputType)
      ) {
        invalidDate.current = true;

        if (control.State.InputType === TNclInputType.nitDate) {
          newText = "0001-01-01";
        } else if (control.State.InputType === TNclInputType.nitDateTime) {
          newText = "0001-01-01T00:00";
        } else if (control.State.InputType === TNclInputType.nitTime) {
          newText = "00:00:00:000";
        }
      }

      if (control.State.InputType === TNclInputType.nitTime) {
        newText = getTimeByFormat(data.Format, newText);
      }
    }

    if (data.PasteText && data.PasteText.length > 0) {
      if (input.current && input.current.selectionStart != null && input.current.selectionEnd != null) {
        //Pokud se jedná o TextAreu
        if (isTextArea()) {
          newText = newText.substring(0, input.current.selectionStart) + data.PasteText + newText.substring(input.current.selectionEnd);

          const newPos = input.current.selectionStart + data.PasteText.length;
          lastSelectedPosition.current.start = newPos;
          lastSelectedPosition.current.end = newPos;
        } else {
          //Pokud se jedná o Input
          if (input.current.selectionStart >= 0 && input.current.selectionStart < newText.length) {
            if (input.current.selectionEnd >= 0 && input.current.selectionEnd < newText.length) {
              newText = newText.substring(0, input.current.selectionStart) + data.PasteText + newText.substring(input.current.selectionEnd);
            } else {
              newText = newText.substring(0, input.current.selectionStart) + data.PasteText + newText.substring(input.current.selectionStart);
            }
          } else {
            newText = newText + data.PasteText;
          }
        }
      } else {
        newText = newText + data.PasteText;
      }
    }

    return newText;
  }

  function getHorizontalAlignCss(): string {
    switch (data.HorizontalAlignment) {
      case HorizontalAlignment.fhaLeft:
        return css.in_text_left;
      case HorizontalAlignment.fhaRight:
        return css.in_text_right;
      case HorizontalAlignment.fhaCenter:
        return css.in_text_center;
      default:
        return css.in_text_left;
    }
  }

  function isTextArea(): boolean {
    if (control.State.InputType === TNclInputType.nitString && data.MultiLineMode) return true;
    return false;
  }

  function getStyle(): React.CSSProperties {
    return {
      backgroundColor: ColumnBaseHelper.getBackgroundColor(data.FormattingStyle, false, false, control.VCX),
      color: ColumnBaseHelper.getForegroundColor(data.FormattingStyle, false, false, control.VCX),
      fontWeight: ColumnBaseHelper.IsBold(data.FormattingStyle) ? "bold" : "normal",
      fontStyle: ColumnBaseHelper.IsItalic(data.FormattingStyle) ? "italic" : "normal",
      textDecoration: getTextDecoration(ColumnBaseHelper.IsStrike(data.FormattingStyle), ColumnBaseHelper.IsUnderline(data.FormattingStyle)),
    };
  }

  function getPrefixSufix(isPrefix: boolean) {
    let glyphId = "";
    let text = "";
    let valAs: TUFDisplayValueAs;

    if (isPrefix) {
      glyphId = data.PrefixGlyphId;
      text = data.PrefixText;
      valAs = control.Ncl.PrefixDisplayAs;
    } else {
      glyphId = data.SuffixGlyphId;
      text = data.SuffixText;
      valAs = control.Ncl.SuffixDisplayAs;
    }

    switch (valAs) {
      case TUFDisplayValueAs.dvaImage:
        return (
          <K2Img
            glyphId={glyphId}
            vcx={control.VCX}
            strokeColor={control.VCX.getColor(control.VCX.Data.ColorMap.BaseColorBck1)}
            width={control.VCX.InputControl.getEditHeight(1)}
            height={control.VCX.InputControl.getEditHeight(1)}
          />
        );
      case TUFDisplayValueAs.dvaText:
        return (
          text && (
            <span style={getStyle()} className={css.in_prefix_suffix}>
              <span>{text}</span>
            </span>
          )
        );
      default:
        return null;
    }
  }

  function getTextFromPosition(option: TextPosition): string {
    if (input.current && value && input.current.selectionStart && input.current.selectionStart > 0 && input.current.selectionStart < value.length) {
      switch (option) {
        case TextPosition.beforeCursor:
          return value[input.current.selectionStart - 1];
        case TextPosition.afterCursor:
          return value[input.current.selectionStart];
        case TextPosition.selected:
          if (input.current.selectionEnd && input.current.selectionStart < input.current.selectionEnd) {
            return value.slice(input.current.selectionStart, input.current.selectionEnd);
          }
          break;
        default:
          break;
      }
    }

    return "";
  }

  function revealPassword() {
    setIsRevealedPassword(!isRevealedPassword);
  }

  function initRef(element: HTMLInputElement | HTMLTextAreaElement) {
    input.current = element;

    if (control instanceof ClientNclInput) {
      control.inputRef = element;
    }
  }

  function setNewValue(value: string) {
    setValue(value);
  }

  function getInputTag(): JSX.Element {
    if (Context.DeviceInfo.IndependentFormatMode) {
      return (
        <K2InnerSimpleInput
          controlUID={control.MetaData.ControlUID}
          vrUID={control.getRealizerUID()}
          onFocus={handleFocus}
          onBlur={handleBlur}
          change={change}
          onDrop={handleDrop}
          onPaste={handlePaste}
          onCut={handleCut}
          onCopy={handleCopy}
          showContextMenu={() => showContextMenu()}
          className={getHorizontalAlignCss()}
          style={getStyle()}
          revealPassword={isRevealedPassword}
          blockSoftKeyboard={props.blockSoftKeyboard}
          lastSelectedPosition={lastSelectedPosition}
          focus={isFocused}
          initRef={initRef}
          value={value}
          position={position}
          control={control}
          setValue={setNewValue}
          data={data}
          invalidDate={invalidDate.current}
        />
      );
    } else {
      return (
        <K2InnerInput
          controlUID={control.MetaData.ControlUID}
          vrUID={control.getRealizerUID()}
          onFocus={handleFocus}
          onBlur={handleBlur}
          change={change}
          onDrop={handleDrop}
          onKeyDown={handleKeyDown}
          onPaste={handlePaste}
          onCut={handleCut}
          onCopy={handleCopy}
          className={getHorizontalAlignCss()}
          style={getStyle()}
          revealPassword={isRevealedPassword}
          blockSoftKeyboard={props.blockSoftKeyboard}
          lastSelectedPosition={lastSelectedPosition}
          focus={isFocused}
          initRef={initRef}
          value={value}
          position={position}
          control={control}
          setValue={setNewValue}
          data={data}
        />
      );
    }
  }

  function change(value: string) {
    control.change(value, false);

    switch (data.AcceptMode) {
      case TAcceptMode.amImmediate:
        control.accept();
        break;
      case TAcceptMode.amDelayed:
        if (timer.current) {
          window.clearTimeout(timer.current);
        }

        timer.current = window.setTimeout(() => {
          control.accept();
        }, 1000);
        break;
    }
  }

  function handleFocus(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) {
    if (control.State.ReadOnly) return;
    if (!(control instanceof ClientNclInput)) control.setActiveControlRequested();
    if (!(control instanceof ClientNclInput)) control.setAsActiveControl();
    setIsFocused(true);
    e.stopPropagation();
  }

  function handleBlur() {
    setIsFocused(false);
  }

  function showContextMenu() {
    control.contextMenu();
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement | HTMLDivElement>) {
    if (control.isDateOrTime() && (e.key === "Backspace" || e.key === "Delete")) {
      let value: string = getTextFromPosition(TextPosition.selected);

      if (!value) {
        value = getTextFromPosition(e.key === "Backspace" ? TextPosition.beforeCursor : TextPosition.afterCursor);
      }

      if (value && value.indexOf(FormatUtils.getSeparator(control.State.InputType)) >= 0) {
        e.preventDefault();
        e.stopPropagation();
        return;
      }
    }

    if (e.key.length === 1) {
      if (checkInput(e.key) > EditCheckValueResult.default) {
        e.preventDefault();
        e.stopPropagation();

        return;
      }
    }
  }

  function handlePaste(e: React.ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>) {
    if (checkInput(e.clipboardData.getData("text").trim()) > EditCheckValueResult.default) {
      e.preventDefault();
      return;
    }
  }

  function handleDrop(e: React.DragEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const data = e.dataTransfer.getData("text").trim();
    const value = checkInput(data, true);

    switch (value) {
      case EditCheckValueResult.block:
        e.preventDefault();
        break;
      case EditCheckValueResult.default:
        setValue(data);
        e.preventDefault();
        input.current?.focus();
        break;
      default:
        input.current?.focus();
        break;
    }
  }

  function checkInput(key: string, replaceAll?: boolean): EditCheckValueResult {
    if (!input.current || control.State.ReadOnly) return EditCheckValueResult.default;
    if (input.current.selectionStart && input.current.selectionStart < 0 && !replaceAll) return EditCheckValueResult.default;

    const selection: InputSelectionText = {
      start: input.current.selectionStart != null && input.current.selectionStart >= 0 ? input.current.selectionStart : value.length,
      end: input.current.selectionEnd != null && input.current.selectionEnd >= 0 ? input.current.selectionEnd : value.length,
    };
    const result = control.checkInput(value, key, selection, replaceAll);

    switch (result.Result) {
      case EditCheckValueResult.block:
        //beep???
        break;
      case EditCheckValueResult.newValue:
        setValue(result.Value);
        setPosition(result.ToPosition);
        setPositionVersion(Date.now());
        break;
      default:
        break;
    }

    return result.Result;
  }

  function handleCopy(e: React.ClipboardEvent<HTMLInputElement>) {
    const newValue = control.getClipboardText(e.currentTarget.value);
    if (newValue !== e.currentTarget.value) {
      e.preventDefault();
      e.clipboardData.setData("text/plain", newValue);
    }
  }

  function handleCut(e: React.ClipboardEvent<HTMLInputElement>) {
    const newValue = control.getClipboardText(e.currentTarget.value);
    if (newValue !== e.currentTarget.value) {
      e.preventDefault();
      e.clipboardData.setData("text/plain", newValue);
      e.currentTarget.value = "";
    }
  }

  return (
    <div
      data-k2-errors-count={data.ErrorsCount > 0 ? data.ErrorsCount : undefined}
      style={StyleHelper(control, props.style)}
      ref={element}
      className={css.in_input_base}
    >
      <K2LabelInput
        titleVisible={control.isVisibleTitle()}
        title={data.Title}
        errorsCount={data.ErrorsCount}
        warningsCount={data.WarningsCount}
        focused={isFocused}
        inEditMode={control.InEditMode}
        modified={data.Modified}
        readOnly={data.ReadOnly}
        vcx={control.VCX}
        frgtData={control.Ncl.FrgtData}
        id={control.Ncl.Name}
      >
        <div className={css.in_input_prefix_suffix} ref={innerElement}>
          {getPrefixSufix(true)}
          {getInputTag()}
          {getPrefixSufix(false)}
        </div>
        {control?.ToolBar?.Actions?.length > 0 && (
          <K2ToolBar
            key={control.ToolBar.MetaData.ControlUID}
            controlUID={control.ToolBar.MetaData.ControlUID}
            vrUID={props.vrUID}
            className={css.in_toolbar}
          />
        )}
        {control.Ncl.FrgtData.IsPassword && (
          <button type="button" className="button" style={{ border: "none", backgroundColor: "transparent", minWidth: "min-content" }} onClick={revealPassword}>
            <K2Img
              width={control.VCX.MinRowHeight}
              height={control.VCX.MinRowHeight}
              glyphId={isRevealedPassword ? "wui*eye.no" : "wui*eye"}
              vcx={control.VCX}
              strokeColor={control.VCX.getColor(control.VCX.Data.ColorMap.BaseColorBck1)}
            />
          </button>
        )}
      </K2LabelInput>
    </div>
  );
}
