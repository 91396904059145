import React, { memo } from "react";
import { NodeProps } from "reactflow";
// eslint-disable-next-line react/display-name
export default memo((props: NodeProps) => {
  return (
    <>
      <div className={"node-zeroPoint"}></div>
    </>
  );
});
