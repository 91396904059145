import { Context } from "../appcontext";
import { getApplicationUrl, queryVariable } from "./common";
import { TLanguageCodeList, languageCodeToStringMap } from "./communication.base";
import cs from "../../wwwroot/locales/cs.json";

export class LocalizationManager {
  private lang: string;
  private promise: Promise<any>;
  private resources: { [key: string]: { [key: string]: string } } = { cs: cs };

  constructor() {
    // Nastavení z URL
    const queryLang = queryVariable("lang", undefined);
    if (queryLang) localStorage.setItem("lang", queryLang);

    // Nastavení načítaného jazyka
    if (localStorage.getItem("lang") === null) {
      this.lang = navigator.language.split("-")[0];
    } else {
      this.lang = localStorage.getItem("lang");
    }

    // Načtení textů
    this.promise = this.loadResources();
  }

  /** Vrátí hodnotu proměnné z jazykové sady */
  public resolve(key: string): string {
    if (this.resources[this.lang] && this.resources[this.lang][key]) {
      return this.resources[this.lang][key];
    }

    // Vyhledá v již načtených lokalizacích
    for (const lang in this.resources) {
      if (this.resources[lang] && this.resources[lang][key]) {
        return this.resources[lang][key];
      }
    }
    return undefined;
  }

  /** Pro nastavení lokalizace po obdržení konfigurace ze serveru */
  public setLang(lang: TLanguageCodeList) {
    this.lang = languageCodeToStringMap[lang];
    this.loadResources();
  }

  /** Načtení textů ze soubrou */
  private loadResources(): Promise<any> {
    if (this.resources[this.lang]) {
      return new Promise<any>((resolve, reject) => {
        resolve(true);
      });
    }

    try {
      const promise = fetch(new URL(`locales/${this.lang}.json`, getApplicationUrl()).toString());
      promise
        .then((resp) => {
          if (resp.ok) {
            resp.json().then((data) => {
              this.resources[this.lang] = data;
              if (Context.getApplication()?.LocalizationVersion !== undefined) Context.getApplication().LocalizationVersion++;
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
      return promise;
    } catch (err) {
      console.log(err);
      return null;
    }
  }
}
