import React from "react";
import { createRoot } from "react-dom/client";
import { Context } from "../../appcontext";
import { VisualContext } from "../../common/visualContext";
import { VCXContext } from "../../context";
import K2Img from "../Image/K2Img";
import K2TruncateText from "../Text/K2TruncateText";
import css from "./ClientDialog.scss";

interface ClientDialogProps {
  action: () => void;
  caption: string;
}

export const K2ClientDialog = (props: ClientDialogProps) => {
  const handleClick = () => {
    if (props.action) {
      props.action();
    }
  };

  let vcx = Context.getApplication()?.appViewRealizer?.VCX?.createVCXForZoomFactor(160);
  if (!vcx) vcx = VisualContext.Default.createVCXForZoomFactor(160);

  return (
    <>
      <div className="k2_client_dialog_overlay" />
      <div className="k2_client_dialog">
        <div>
          <K2TruncateText line={3} className={css.cd}>
            {props.caption}
          </K2TruncateText>
        </div>
        <button className="loginBtn" onClick={handleClick}>
          <K2Img glyphId="wui*ok" height={24} vcx={Context.getApplication()?.appViewRealizer?.VCX} />
        </button>
      </div>
    </>
  );
};

export function showClientDialog(caption: string, action: () => void) {
  let el = document.createElement("div");
  document.body.appendChild(el);
  const root = createRoot(el);

  root.render(
    <VCXContext.Provider value={{ vcx: Context.getApplication()?.appViewRealizer?.VCX }}>
      <K2ClientDialog
        action={() => {
          if (action) action();
          el.remove();
          el = undefined;
        }}
        caption={caption}
      />
    </VCXContext.Provider>
  );
}
