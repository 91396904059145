import React, { MutableRefObject, useContext, useEffect, useRef, useState } from "react";
import { Context, __ } from "../../appcontext";
import { cJsonFunctionMainMenuByRID, DataFromDM } from "../../common/communication.base";
import { VCXContext } from "../../context";
import { useAnimationEnd } from "../hooks";
import K2Img from "../Image/K2Img";
import css from "./MainMenu.scss";

declare global {
  interface Node {
    compareDocumentPosition(other: Node): number;
  }
}

interface MenuMapItem {
  id: number;
  ref: HTMLButtonElement | null;
  focus: boolean;
}

const K2MainMenu = (props: { data: DataFromDM[]; setShowNewMenu: React.Dispatch<React.SetStateAction<boolean>> }) => {
  const vcxContext = useContext(VCXContext);
  const [data, setData] = useState(props.data);
  const [query, setQuery] = useState("");
  const listMap: MenuMapItem[] = [];
  const activeId = useRef(-1);
  const sorted = useRef(false);

  useEffect(() => {
    sorted.current = false;
  }, [data]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value
      .normalize("NFD")
      .replace(/\p{Diacritic}/gu, "")
      .toLowerCase();

    setQuery(value);
  };

  const handleClick = (dataDM: DataFromDM) => {
    if (!dataDM.IsFolder) {
      props.setShowNewMenu(false);

      const app = Context.getApplication();

      const json = {
        RealizerUID: app.appViewRealizer.getRealizerUID(),
        Data: [
          {
            ControlUID: app.MainTabControlUID,
            Functions: [{ Name: cJsonFunctionMainMenuByRID, Args: [dataDM.RID] }],
          },
        ],
      };

      const request = {
        messageType: 2,
        realizerUID: app.appViewRealizer.getRealizerUID(),
        json: JSON.stringify(json),
        realizeCounter: app.appViewRealizer.getRealizeCounter(),
      };

      Context.getApplication().sendMessage(request, undefined);

      return;
    }

    const newData = data.map((item) => {
      if (dataDM.RID === item.RID) {
        return {
          ...item,
          Expanded: !item.Expanded,
        };
      } else {
        return item;
      }
    });

    setData(newData);
  };

  const getItem = (itemId: number) => {
    return data.find((item) => item.RID === itemId)!;
  };

  const getRootChildrenIds = () => {
    const childrenIds = data.map((item) => {
      if (!query) {
        if (item.ParentRID === 0) return item.RID;

        return;
      }

      // filtering

      let searchedItemRID: number | undefined;
      let itemWords = item.NormalizedName.trim().split(" ");
      const queryWords = query
        .trim()
        .split(" ")
        .filter((word) => word !== "");

      const allQueryWordsFound = queryWords.map((queryWord) => {
        const queryWordFound = itemWords.some((itemWord) => {
          if (itemWord.includes(queryWord)) {
            itemWords = itemWords.filter((word) => word !== itemWord);

            return true;
          }

          return false;
        });

        if (queryWordFound) {
          searchedItemRID = item.RID;

          return true;
        }

        return false;
      });

      const itemFound = allQueryWordsFound.every((queryWord) => queryWord);

      if (!itemFound || item.IsFolder || item.IsInvalidRecord || !searchedItemRID) return;

      return searchedItemRID;
    });

    return childrenIds.filter((item) => item != null);
  };
  function keyActionHandler(e: React.KeyboardEvent<HTMLDivElement>) {
    if (e.code === "Escape") props.setShowNewMenu(false);

    if (!sorted.current) {
      listMap.sort(function (a, b) {
        if (a.ref === b.ref || a.ref == null || b.ref == null) return 0;
        if (a.ref.compareDocumentPosition(b.ref) & 2) {
          // b comes before a
          return 1;
        }
        return -1;
      });
      sorted.current = true;
    }

    e.stopPropagation();
    if (e.code === "ArrowDown") {
      e.preventDefault();
      setFocusAction(1);
    } else if (e.code === "ArrowUp") {
      e.preventDefault();
      setFocusAction(-1);
    }
  }

  function setFocusAction(action: number) {
    const item = listMap.find((obj) => {
      return obj.focus === true;
    });
    if (!item) {
      listMap[0].ref?.focus();
      return;
    }
    const index = listMap.indexOf(item);

    if (action === 1) {
      if (index + 1 >= listMap.length) return;
      listMap[index + 1].ref?.focus();
    } else if (action === -1) {
      if (index - 1 < 0) return;
      listMap[index - 1].ref?.focus();
    }
  }

  return (
    <div className={css.mm_backdrop} onClick={() => props.setShowNewMenu(false)} onKeyDown={keyActionHandler} tabIndex={-1}>
      <div onClick={(e) => e.stopPropagation()} className={css.mm}>
        <div className={css.mm_controls}>
          <button className={`${css.mm_button} ${css.mm_menu_icon}`} onClick={() => props.setShowNewMenu(false)}>
            <K2Img glyphId="wui*list" height={vcxContext?.vcx.sizeMap(20)} width={vcxContext?.vcx.sizeMap(20)} />
          </button>
          <button className={css.mm_button} onClick={() => props.setShowNewMenu(false)}>
            <K2Img glyphId="wui*close" height={vcxContext?.vcx.sizeMap(20)} width={vcxContext?.vcx.sizeMap(20)} />
          </button>
        </div>
        <p className={css.mm_title}>{__("mainMenu")}</p>
        <div className={css.mm_input_wrapper}>
          <input autoFocus onChange={handleChange} className={css.mm_input} />
          <K2Img
            glyphId="wui*search"
            height={vcxContext?.vcx.sizeMap(15)}
            width={vcxContext?.vcx.sizeMap(15)}
            style={{ color: "var(--ColorMap-ContentNormalColorFrg)" }}
          />
        </div>
        <K2MenuList getItem={getItem} onClick={handleClick} childrenIds={getRootChildrenIds()} expanded={true} listMap={listMap} activeId={activeId} />
      </div>
    </div>
  );
};

export default K2MainMenu;

interface MenuListProps {
  childrenIds: (number | undefined)[];
  expanded: boolean;
  listMap: MenuMapItem[];
  activeId: MutableRefObject<number>;
  getItem: (itemId: number) => DataFromDM;
  onClick: (dataDM: DataFromDM) => void;
}

const K2MenuList = (props: MenuListProps) => {
  const vcxContext = useContext(VCXContext);
  const [render, handleAnimationEnd] = useAnimationEnd(props.expanded);

  function expandedItemsCount(itemIds: number[], count: number) {
    itemIds.map((itemId) => {
      const item = props.getItem(itemId);

      if (item.IsInvalidRecord) return;

      count += 1;

      if (item.ChildrenIds.length > 0 && item.Expanded) {
        count = expandedItemsCount(item.ChildrenIds, count);
      }
    });

    return count;
  }

  const getIcon = (item: DataFromDM) => {
    if (item.IsFolder && item.Expanded) {
      const folderIconArray = item.Icon.trim().split(".");
      folderIconArray.splice(1, 0, "open");
      const folderIconOpened = folderIconArray.join(".");

      return folderIconOpened;
    }

    return item.Icon.trim();
  };

  function handleButtonFocus(id: number) {
    const item = props.listMap.find((obj) => {
      return obj.id === id;
    });
    if (item) {
      item.focus = true;
      props.activeId.current = id;
    }
  }
  function handleButtonBlur(id: number) {
    const item = props.listMap.find((obj) => {
      return obj.id === id;
    });
    if (item) {
      item.focus = false;
    }
  }

  if (!render) return null;

  return (
    <ul className={`${css.mm_list} ${props.expanded ? `${css.mm_expand}` : `${css.mm_collapse}`}`} onAnimationEnd={handleAnimationEnd}>
      {props.childrenIds.map((childId) => {
        if (childId === undefined) return;

        const item = props.getItem(childId);
        if (item.IsInvalidRecord) return null;

        return (
          <li
            key={childId}
            style={{ ["--height" as string]: `${vcxContext?.vcx.sizeMap(expandedItemsCount(item.ChildrenIds, 0) * 42)}px` }}
            className={css.mm_item}
          >
            <button
              ref={(el) => {
                props.listMap.push({ id: childId, ref: el, focus: props.activeId.current === childId });
              }}
              className={css.mm_button}
              onClick={() => props.onClick(item)}
              tabIndex={0}
              onFocus={() => handleButtonFocus(childId)}
              onBlur={(e) => handleButtonBlur(childId)}
            >
              <K2Img glyphId={getIcon(item)} height={vcxContext?.vcx.sizeMap(30)} width={vcxContext?.vcx.sizeMap(30)} />
              {item.Name}
              {item.IsFolder && (
                <K2Img
                  glyphId="wui*roofdown"
                  height={vcxContext?.vcx.sizeMap(15)}
                  width={vcxContext?.vcx.sizeMap(15)}
                  style={{ marginLeft: "auto", transform: item.Expanded ? "rotate(180deg)" : undefined, transition: "transform 0.3s" }}
                />
              )}
            </button>
            {item.ChildrenIds.length > 0 && (
              <K2MenuList
                getItem={props.getItem}
                onClick={props.onClick}
                childrenIds={item.ChildrenIds}
                expanded={Boolean(item.Expanded)}
                listMap={props.listMap}
                activeId={props.activeId}
              />
            )}
          </li>
        );
      })}
    </ul>
  );
};
