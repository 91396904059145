import React, { useRef } from "react";
import { useState } from "react";
import { Context } from "../../appcontext";
import { CSNclMenuItemBaseMetadata, IconPosition, Orientation, UpdateControl, UpdateMenuContainer, UpdateMenuItem } from "../../common/communication.base";
import {
  NclCommandItem,
  NclControlBase,
  NclMenu,
  NclMenuContainer,
  NclMenuDivider,
  NclMenuGroup,
  NclMenuItem,
  NclMenuItemBase,
  NclToolBar,
  UFNclControlBase,
} from "../../common/components.ncl";
import { ViewRealizer, ViewRealizerManager } from "../../viewrealizer";
import K2Action from "../Action/K2Action";
import ActionSeparator from "../ActionSeparator/ActionSeparator";
import { useServerState } from "../hooks";
import { AcquireControl, StyleHelper, WithContextPlacementProps } from "../k2hoc";
import K2Img from "../Image/K2Img";
import K2TruncateText from "../Text/K2TruncateText";
import css from "./Menu.scss";

interface MenuProps extends WithContextPlacementProps {
  depth: number;
  subMenuDisplayInside: boolean;
  parentMenuWidth?: number;
}

function K2Menu(props: MenuProps) {
  const [control, data, element] = useServerState<NclMenuItemBase<CSNclMenuItemBaseMetadata, UpdateControl>, UpdateMenuContainer, HTMLDivElement>(
    props.controlUID,
    props.vrUID,
    (ctrl) => ctrl instanceof NclMenuItemBase
  );
  const openMenuTimer = useRef(0);
  const [submenuPosition, setSubmenuPosition] = useState(IconPosition.ipRight);
  const menuRef = useRef<HTMLDivElement>(null);
  const isRoot = !(control.Parent instanceof NclMenuItemBase);
  const actions = getActions();
  let menu: JSX.Element | null = null;
  let subMenu: JSX.Element | null = null;

  function handleClick(e: React.MouseEvent<HTMLButtonElement>) {
    if (control instanceof NclMenuItemBase && data.Enabled !== false) {
      if (control instanceof NclMenuGroup) {
        e.stopPropagation();
      }

      if (control instanceof NclMenuContainer) {
        control.execute(undefined, undefined, !props.subMenuDisplayInside);
      } else {
        control.execute();
      }
    }

    e.stopPropagation();
  }

  function handleMouseEnter() {
    if (props.subMenuDisplayInside) return;
    if (!(control instanceof NclMenuContainer)) return;

    openMenuTimer.current = window.setTimeout(() => {
      control.execute(false, true);
    }, 400);

    if (props.parentMenuWidth && element.current?.getBoundingClientRect().left) {
      if (props.subMenuDisplayInside) {
        setSubmenuPosition(IconPosition.ipCenter);
      } else if (
        element.current?.getBoundingClientRect().left + control.VCX.sizeMap(props.parentMenuWidth) + control.VCX.sizeMap(control.getMenuWidth()) >
        window.innerWidth
      ) {
        setSubmenuPosition(IconPosition.ipLeft);
      } else {
        setSubmenuPosition(IconPosition.ipRight);
      }
    }
  }

  function handleMouseLeave() {
    if (props.subMenuDisplayInside) return;
    if (!(control instanceof NclMenuContainer)) return;

    if (openMenuTimer.current) {
      window.clearTimeout(openMenuTimer.current);
    }

    window.setTimeout(() => {
      control.execute(true);
    }, 400);
  }

  function getMenuItemIcon() {
    let background = "none";
    let glyphId: string = control.Ncl.GlyphId;

    if (control instanceof NclMenuItem && data instanceof UpdateMenuItem && data.Checked) {
      background = control.VCX.getColor(control.VCX.Data.ColorMap.GridRulerColorBck);
      if (!control.Ncl.GlyphId) {
        glyphId = "wui*ok";
      }
    }

    if (!glyphId) {
      return <div style={{ width: "20px" }} />;
    } else {
      return (
        <K2Img glyphId={glyphId} vcx={control.VCX} style={{ textAlign: "center", alignSelf: "center", backgroundColor: background }} height={20} width={20} />
      );
    }
  }

  function getMenuHotKey() {
    if (props.subMenuDisplayInside) return;

    if (control instanceof NclMenuItem && control.Ncl.HotKey) {
      return <p className={css.menu_hotkey}>{control.Ncl.HotKey}</p>;
    }
  }

  function getActions() {
    const VRs: ViewRealizer[] = Array.from(ViewRealizerManager.getViewRealizers());
    let ctrl: NclControlBase;
    let actions: UFNclControlBase[] = [];

    VRs.map((VR) => {
      if (control instanceof NclMenu) {
        ctrl = VR.getControlByUID(control.Ncl.SourceControlUID);

        if (ctrl instanceof NclCommandItem && ctrl.Parent instanceof NclToolBar && ctrl.Parent.MenuBtn?.Ncl.ControlUID === control.Ncl.SourceControlUID) {
          actions = ctrl.Parent.Actions.filter((action) => action instanceof NclCommandItem);
        }
      }
    });

    return actions;
  }

  if (!isRoot) {
    //polozka menu se vykresli vzdy kdyz se nejedna o root
    if (control instanceof NclMenuDivider) {
      menu = <ActionSeparator orientation={Orientation.foHorizontal} width={"100%"} height={control.VCX.sizeMap(1)} color={"#00000066"} />;
    } else {
      const menuItemStyle: React.CSSProperties = { height: control.ComputedMinHeight + "px" };
      let menuItemClass = "menu_button";
      let menuIconStyle: React.CSSProperties = { flex: "0 0 auto" };

      if (control instanceof NclMenuContainer && data.isOpen) {
        menuItemClass += " active";
        menuIconStyle = { ...menuIconStyle, transform: "rotate(180deg)" };
      }

      menu = (
        <button style={menuItemStyle} onClick={handleClick} className={menuItemClass} disabled={data.Enabled === false}>
          {getMenuItemIcon()}
          <K2TruncateText style={{ marginLeft: "10px", marginRight: "auto" }}>{control.Ncl.Caption}</K2TruncateText>
          {getMenuHotKey()}
          {control instanceof NclMenuContainer && (
            <K2Img
              glyphId={props.subMenuDisplayInside ? "wui*roofdown" : "wui*roofright"}
              vcx={control.VCX}
              style={menuIconStyle}
              height={control.VCX.LabelControl.getHeight(1)}
              width={control.VCX.LabelControl.getHeight(1)}
            />
          )}
        </button>
      );
    }
  }

  if (control instanceof NclMenuContainer && data.isOpen) {
    // container se vygeneruje pokud ma nastaven stav isOpen

    subMenu = (
      <>
        {actions.length > 0 && (
          <div className={css.menu_responsive_actions}>
            {actions.map((action) => {
              const ctrl = AcquireControl(action.MetaData.ControlUID, action.getRealizerUID(), (action) => action instanceof NclCommandItem);
              if (!action.State.Visible) return null;

              return (
                <div
                  className={css.menu_responsive_action}
                  key={ctrl.MetaData.ControlUID}
                  onClick={() => {
                    Context.getApplication().ActionControlData = { actionControlUID: action.MetaData.ControlUID, realizerUID: action.getRealizerUID() };
                    ViewRealizerManager.getViewRealizer(control.getRealizerUID()).closeRequest();
                  }}
                >
                  <K2Action
                    controlUID={action.MetaData.ControlUID}
                    vrUID={action.getRealizerUID()}
                    color={control.VCX.getColor(control.VCX.Data.ColorMap.BaseColorBck1)}
                    preventClick={true}
                  />
                </div>
              );
            })}
          </div>
        )}
        {control.Actions?.map((item) => {
          return (
            <K2Menu
              controlUID={item.MetaData.ControlUID}
              vrUID={item.getRealizerUID()}
              key={item.MetaData.ControlUID}
              depth={props.depth + 1}
              subMenuDisplayInside={props.subMenuDisplayInside}
              parentMenuWidth={control instanceof NclMenuContainer ? control.getMenuWidth() : 0}
            />
          );
        })}
      </>
    );
    if (isRoot) {
      // root je automaticky rozbaleny kontejner
      menu = subMenu;
      subMenu = null;
    }
  }

  if (subMenu && menu) {
    // pokud ma submenu i menu tak se vygeneruji vedle sebe
    let marginLeft = 0;
    let subMenuUnit = "px";
    let submenuContainerWidth = 0;
    const subMenuStyle: React.CSSProperties = {};

    if (control instanceof NclMenuContainer) {
      submenuContainerWidth = control.getMenuWidth();
    }

    // horizontální posunutí submenu, pokud se nevejde na obrazovku
    if (!props.subMenuDisplayInside) {
      marginLeft = submenuPosition === IconPosition.ipLeft ? submenuContainerWidth * -1 : (props.parentMenuWidth ?? 0) - 6;
    } else {
      marginLeft = props.depth * 10;
      subMenuUnit = "%";
    }

    // Vertikální posunutí submenu, pokud se nevejde na obrazovku
    if (control instanceof NclMenuContainer && !props.subMenuDisplayInside) {
      const totalMenuHeight: number = control.getMenuHeight() + 10;
      const menuPosition = Context.getApplication().getMenuPositon();

      if (menuPosition.y + totalMenuHeight > window.innerHeight) {
        subMenuStyle.bottom = 0;
      }

      if (totalMenuHeight > window.innerHeight) {
        subMenuStyle.height = "98%";
      }
    }

    subMenuStyle.marginLeft = control.VCX.sizeMap(marginLeft) + subMenuUnit;
    subMenuStyle.width = (props.subMenuDisplayInside ? "auto" : control.VCX.sizeMap(submenuContainerWidth)) + subMenuUnit;
    subMenuStyle.zIndex = props.subMenuDisplayInside ? 1 : props.depth * 2;

    menu = (
      <>
        {menu}
        <div ref={menuRef} style={subMenuStyle} className={`${css.menu}${props.subMenuDisplayInside ? "" : ` ${css.menu_sub}`}`}>
          {subMenu}
        </div>
      </>
    );
  }

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={StyleHelper(control, { ...props.style, flex: isRoot ? "1 1 auto" : undefined })}
      className={css.menu}
      ref={element}
    >
      {menu}
    </div>
  );
}

export default K2Menu;
