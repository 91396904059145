import { RESpecialChars } from "./common";
import { TNclInputType } from "./communication.base";

export interface LocalInputInfo {
  regExpr: RegExp;
}

export function createRESeparator(separator: string): string {
  if (RESpecialChars.indexOf(separator) >= 0) {
    return `\\${separator}`;
  }

  return separator;
}

export class FormatUtils {
  private static separatorCache: Array<string> = new Array<string>();
  private static liFormCache: Map<string, LocalInputInfo> = new Map<string, LocalInputInfo>();

  public static getSeparator(type: TNclInputType): string {
    if (type < FormatUtils.separatorCache.length && FormatUtils.separatorCache[type]) return FormatUtils.separatorCache[type];

    switch (type) {
      case TNclInputType.nitTime: {
        const date = new Date("2020-12-24T12:24:59");
        const dateStr = date.toLocaleTimeString();
        const hourStr = date.getHours().toString();
        const hourNdx = dateStr.indexOf(hourStr) + hourStr.length;
        FormatUtils.separatorCache[type] = dateStr.substring(hourNdx, hourNdx + 1).trim();
        return FormatUtils.separatorCache[type];
      }
      case TNclInputType.nitDate: {
        const date = new Date("2020-12-24");
        const dateStr = date.toLocaleDateString();
        const dayStr = date.getDate().toString();
        const monthStr = (date.getMonth() + 1).toString();
        let sepNdx = dateStr.indexOf(dayStr);
        if (sepNdx + dayStr.length < dateStr.length) {
          sepNdx += dayStr.length;
        } else {
          sepNdx = dateStr.indexOf(monthStr) + monthStr.length;
        }
        FormatUtils.separatorCache[type] = dateStr.substring(sepNdx, sepNdx + 1).trim();
        return FormatUtils.separatorCache[type];
      }
      case TNclInputType.nitFloat: {
        const n = 1.1;
        FormatUtils.separatorCache[type] = n.toLocaleString().substring(1, 2);
        return FormatUtils.separatorCache[type];
      }
      case TNclInputType.nitInteger: {
        FormatUtils.separatorCache[type] = undefined;
        return FormatUtils.separatorCache[type];
      }
      case TNclInputType.nitDateTime:
        FormatUtils.separatorCache[type] = " ";
        return FormatUtils.separatorCache[type];
    }
  }

  public static getTimeInputTypeByFormat(format: string) {
    if (!format) return undefined;
    const result: LocalInputInfo = { regExpr: undefined };

    const formats: Map<string, number> = new Map<string, number>();
    const separator = FormatUtils.getSeparator(TNclInputType.nitTime);
    const resSep = createRESeparator(separator);
    const segmentsExpr: string[] = [];
    let hoursTo24 = true;
    for (let i = 0; i < format.length; i++) {
      const chr = format[i];
      if (chr == "^") {
        hoursTo24 = false;
      } else {
        if (chr == separator) continue;
        let v = 0;
        if (formats.has(chr)) v = formats.get(chr);
        formats.set(chr, v + 1);
      }
    }

    let regBuilder = "";
    let index = 0;
    let value: string;
    formats.forEach((item, key, map) => {
      switch (key) {
        case "h": //hours
          if (hoursTo24) {
            value = "([01]?\\d|2[0-3])";
          } else value = `(\\d{1,2})`;
          break;
        case "n": //minutes
        case "s": //seconds
          if (item > 1) {
            value = "([0-5]?\\d?)";
          } else {
            value = "(\\d)";
          }
          break;
        case "z": //miliseconds
          value = `(\\d${item})`;
          break;
        default:
          break;
      }
      segmentsExpr[index] = `(${value})`;
      index++;
    });

    for (let i = 0; i < segmentsExpr.length; i++) {
      for (let j = 0; j <= i; j++) {
        regBuilder += segmentsExpr[j];
        if (j < i) {
          regBuilder += resSep;
        }
      }
      if (i < segmentsExpr.length - 1) {
        regBuilder += `${resSep}?$|`;
      } else {
        regBuilder += "$";
      }
    }

    result.regExpr = new RegExp(`^(${regBuilder})`);

    FormatUtils.liFormCache.set(format, result);

    return result;
  }

  public static getLocalInfoByFormat(format: string): LocalInputInfo {
    if (FormatUtils.liFormCache && FormatUtils.liFormCache.has(format)) {
      return FormatUtils.liFormCache.get(format);
    }
  }
}
