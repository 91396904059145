import React, { useEffect, useRef, useState } from "react";
import L from "leaflet";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import GeocodingManager, { Coordinates } from "./GeocodingManager";
import { __ } from "../../appcontext";
import K2Img from "../Image/K2Img";
import ReactResizeDetector from "react-resize-detector";

const DefaultIcon = L.icon({
  iconUrl: "img/marker-icon.png",
  shadowUrl: "img/marker-shadow.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41],
});

L.Marker.prototype.options.icon = DefaultIcon;

export type OpenStreetMapProps = {
  data: {
    Street: string;
    Country: string;
    DescrNo: string;
    OrientNo: string;
    City: string;
    GPS: string;
  };
  mapLink: string;
};

export enum GeoState {
  gpsStale,
  gpsLoading,
}

const K2OpenStreetMap = (props: OpenStreetMapProps) => {
  const map = useRef<L.Map>();
  const [GPS, setGPS] = useState<Coordinates>(undefined);
  const [GEOState, setGEOState] = useState<GeoState>(GeoState.gpsStale);

  useEffect(() => {
    if (props.data.GPS) {
      console.log("GPS " + props.data.GPS);
      const coord = props.data.GPS.split(" ");
      setGPS({ lat: parseFloat(coord[0]), lng: parseFloat(coord[1]) });
    } else {
      setGEOState(GeoState.gpsLoading);
      const geo = GeocodingManager.searchForCoordiantes({
        street: `${props.data.Street} ${props.data.DescrNo}/${props.data.OrientNo}`,
        country: props.data.Country,
        city: props.data.City,
      });
      geo
        .then((result) => {
          setGPS(result);
        })
        .catch((error) => {
          setGPS(undefined);
        })
        .finally(() => {
          setGEOState(GeoState.gpsStale);
        });
    }
  }, [props.data]);

  useEffect(() => {
    map.current?.setView([GPS.lat, GPS.lng], 14);
  }, [GPS]);

  function onResize() {
    map.current?.invalidateSize();
  }

  function setLinkAttr() {
    const contr = map.current?.getContainer()?.querySelectorAll(".leaflet-control-attribution.leaflet-control a");
    contr?.forEach((el) => {
      el.setAttribute("target", "_blank");
    });
  }

  return (
    <>
      <div className="osm_map">
        {!GPS && (
          <div className={"map_noresult"}>
            <span>{__("noGeocodeResult")}</span>
          </div>
        )}
        {GPS && (
          <>
            <ReactResizeDetector handleWidth handleHeight onResize={onResize}>
              <MapContainer ref={map} center={[GPS.lat, GPS.lng]} zoom={14} scrollWheelZoom={true}>
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  eventHandlers={{ load: setLinkAttr }}
                />
                <Marker position={[GPS.lat, GPS.lng]}></Marker>
              </MapContainer>
            </ReactResizeDetector>
            <a className={"map_link"} title={__("mapOpenLinkOn") + " google maps"} href={props.mapLink} target="_blank" rel="noreferrer">
              <K2Img glyphId="wui*editor.link.svg" style={{ textAlign: "center", alignSelf: "center" }} height={15} width={15} />
            </a>
          </>
        )}
      </div>
    </>
  );
};

export default K2OpenStreetMap;
